import { IRemoteConfig } from '../shared/interfaces/common';
import { ENV } from './env';

let configs: IRemoteConfig | undefined;

export const initConfig = (): Promise<IRemoteConfig> => {
    // console.log(process.env);
    return new Promise((resolve, reject) => {

        /**
         * Some data from remote config API place here
         */
        const remoteConfig = {};
        /**
         * Some data from remote config API place here
         */

        const config: IRemoteConfig = {
            accessToken: ENV.ACCESS_TOKEN,
            endpoint: ENV.API_URL,
            domain: ENV.API_URL,
            googleApiKey: ENV.API_KEY,
            adminUsername: ENV.USERNAME,
            adminPassword: ENV.PASSWORD,
            ...remoteConfig
        };

        configs = config;

        resolve(config);
    });
};

export const getBaseConfig = (): IRemoteConfig | undefined => {
    return configs;
};
