import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import GetUserDetailStorageUseCase from "../../../../domain/usecases/users/getUserDetailStorage";
import { IAction } from "../../../../shared/interfaces/common";
import { UserActionTypes, getUserDetailStorageSuccess, getUserDetailStorageFail } from "../../actions/users/user.actions";


export const getUserDetailStorageEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.GET_USER_DETAIL_STORAGE),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const usecase = new GetUserDetailStorageUseCase();
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getUserDetailStorageSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getUserDetailStorageFail(error.message));
                    obs.complete();
                });
            });
        })
    );


