import { combineEpics } from "redux-observable";
import { getOrdersEpic } from "./getOrdersEpic";
import { getOrderEpic } from "./getOrderEpic";
import { changeOrderStatusEpic } from "./changeOrderStatusEpic";
import { exportOrdersHistoriesEpic } from "./exportOrdersHistoriesEpic";
import { exportOrdersV2Epic } from "./exportOrdersV2Epic";
import { changeOrderProductStatusEpic } from "./changeOrderProductStatusEpic";
import { getOrdersByCustomerEpic } from "./getOrdersByCustomerEpic";
import { exportOrdersEpic } from "./exportOrdersEpic";
import { exportSalesEpic } from "./exportSalesEpic";
import { updateOrderEpic } from "./updateOrderEpic";
import { updateOrderProductsEpic } from "./updateOrderProductsEpic";
import { getRevenueInformationEpic } from "./getRevenueInformationEpic";
import { exportPurchaseOrdersEpic } from "./exportPurchaseOrdersEpic";
import { exportSakayasInfoSummaryEpic } from "./exportSakayasInfoSummaryEpic";
import { resendOrderConfirmationEpic } from "./resendOrderConfirmationEpic";
import { getPurchasedProductsEpic } from "./getPurchasedProductsEpic";
import { sendProductRejectedNotificationEpic } from "./sendProductRejectedNotificationEpic";
import { saveFirebaseTokenEpic } from "./saveFirebaseTokenEpic";
import { getOrderSummaryEpic } from "./getOrderSummaryEpic";
import { getSakayasInfoSummaryEpic } from "./getSakayasInfoSummaryEpic";
import { generateProductsPdfEpic } from "./generateProductsPdfEpic";
import { createSelectedCollectionEpic } from "./createSelectedCollectionEpic";
import { deleteSelectedCollectionEpic } from "./deleteSelectedCollectionEpic";
import { getProductsSelectedEpic } from "./getProductsSelectedEpic";
import { getFaxFilesEpic } from "./getFaxFilesEpic";
import { getFaxContentEpic } from "./getFaxContentEpic";
import { cancelFaxEpic } from "./cancelFaxEpic";
import { scanFaxImagesEpic } from "./scanFaxImagesEpic";
import { createOrderEpic } from "./createOrderEpic";
import { exportOrdersPosEpic } from "./exportOrdersPosEpic";

export const ordersEpic = combineEpics(
    getOrderEpic,
    getOrdersEpic,
    changeOrderStatusEpic,
    changeOrderProductStatusEpic,
    getOrdersByCustomerEpic,
    exportOrdersEpic,
    exportSalesEpic,
    updateOrderEpic,
    updateOrderProductsEpic,
    getRevenueInformationEpic,
    exportPurchaseOrdersEpic,
    exportSakayasInfoSummaryEpic,
    resendOrderConfirmationEpic,
    getPurchasedProductsEpic,
    sendProductRejectedNotificationEpic,
    saveFirebaseTokenEpic,
    getOrderSummaryEpic,
    getSakayasInfoSummaryEpic,
    generateProductsPdfEpic,
    createSelectedCollectionEpic,
    deleteSelectedCollectionEpic,
    getProductsSelectedEpic,
    scanFaxImagesEpic,
    createOrderEpic,
    getFaxFilesEpic,
    getFaxContentEpic,
    cancelFaxEpic,
    exportOrdersPosEpic,
    exportOrdersHistoriesEpic,
    exportOrdersV2Epic
);