import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { ExportOrdersHistoriesUseCase } from "../../../../domain/usecases/order";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, exportOrdersHistoriesSuccess, exportOrdersHistoriesFail } from "../../actions/orders/order.actions";
import { notifySuccess } from "../../../../utils/toast";
export const exportOrdersHistoriesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.EXPORT_ORDERS_HISTORIES),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ExportOrdersHistoriesUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    // notifySuccess('注文の更新が完了!');
                    obs.next(exportOrdersHistoriesSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(exportOrdersHistoriesFail(error.message));
                    obs.complete();
                });
            });
        })
    );


