import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { calculateAnnouncementTargetFail, calculateAnnouncementTargetSuccess, NotificationActionTypes } from "../../actions/notification/notification.actions";
import { CalculateAnnouncementTargetUseCase } from "../../../../domain/usecases/notification";
import { notifyError, notifySuccess } from "../../../../utils/toast";

export const calculateAnnouncementTargetEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(NotificationActionTypes.CALCULATE_ANNOUNCEMENT_TARGET),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CalculateAnnouncementTargetUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(calculateAnnouncementTargetSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(calculateAnnouncementTargetFail(error.message));
                    obs.complete();
                });
            });
        })
    );


