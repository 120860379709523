import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, getFaxContentSuccess, getFaxContentFail } from "../../actions/orders/order.actions";
import { GetFaxContentUseCase} from "../../../../domain/usecases/order";

export const getFaxContentEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.GET_FAX_CONTENT),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetFaxContentUseCase(payload);
                usecase.execute().then((responses: any) => {
                    var data = responses.data;
                    obs.next(getFaxContentSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getFaxContentFail(error.message));
                    obs.complete();
                });
            });
        })
    );


