import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, getInvoiceDetailSuccess, getInvoiceDetailFail } from "../../actions/invoices/invoices.actions";
import GetInvoiceDetailUseCase from "../../../../domain/usecases/invoices/GetInvoiceDetailUseCase";


export const getInvoiceDetailEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.GET_INVOICE_DETAIL),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetInvoiceDetailUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    // debugger
                    obs.next(getInvoiceDetailSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    // debugger
                    obs.next(getInvoiceDetailFail(error.message));
                    obs.complete();
                });
            });
        })
    );


