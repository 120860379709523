import Type from "../../type";
import { IGetSaleRepresentativePayload } from "../../../../../presentation/redux/actions/interfaces/saleInfo.types";
import { IResource } from "../../../../../shared/interfaces/common/resource";
import { getApiController } from "..";

const GetSalesRepresentatives = (
  payload: IGetSaleRepresentativePayload,
  storeView?: string
): IResource => ({
  Type: Type.Customer,
  Path: `${getApiController(
    storeView
  )}/management/salessakaya/v1.0/ListSalesForSakaya?KeyWord=${
    payload.keyword
  }&page=${payload.page}&pageSize=${payload.pageSize}`,
});

const AddSaleRepresentative = (storeView?: string): IResource => ({
  Type: Type.Customer,
  Path: `${getApiController(
    storeView
  )}/management/salessakaya/v1.0/AddSaleForSakaya`,
});

const ImportSale = (storeView?: string): IResource => ({
  Type: Type.Customer,
  Path: `${getApiController(
    storeView
  )}/management/salessakaya/v1.0/ImportSalesForSakaya`,
});

const DeleteSales = (storeView?: string): IResource => ({
  Type: Type.Customer,
  Path: `${getApiController(
    storeView
  )}/management/salessakaya/v1.0/DeleteSaleForSakaya`,
});

const UpdateSale = (storeView?: string): IResource => ({
  Type: Type.Customer,
  Path: `${getApiController(
    storeView
  )}/management/salessakaya/v1.0/UpdateSaleForSakaya`,
});

export default {
  GetSalesRepresentatives,
  AddSaleRepresentative,
  ImportSale,
  DeleteSales,
  UpdateSale,
};
