import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';

import { IActiveCustomersPayload,ISetActivePricePayload, ICreateCustomerGroupPricePayload, ICreatePricePayload, IGetPriceDetailPayload, IUpdateCustomerGroupPricePayload, IUpdatePricePayload, IImportPricesHistoryPayload, IExportVariantsPayload, ISetColorVariantPayload, IGetFilePayload } from '../../../../presentation/redux/actions/interfaces/product.types';
import { GetDeliveryTimeData, GetDeliveryTimePayload, GetImportDeliveryData, GetImportDeliveryHistoryPayload, IExportPricePayload, IGetProductsPayload, IGetProductsUploadHistoryPayload, ISetOverrideDisplayPayload, ISetProductPriorityPayload, ISetProductVisiblePayload } from '../../../../presentation/redux/actions/products/products.types';
import { APIResponse } from '../../../../models/response/types';

const getSellingPrices = (payload: IGetProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetSellingPrices(payload);
    return apiGateway.doGetRequest(productsResource);
};

const getDiscontinuedPrices = (payload: IGetProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetDiscontinuedPrices(payload);
    return apiGateway.doGetRequest(productsResource);
};

const searchProducts = (payload: IGetProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.SearchProducts(payload);
    return apiGateway.doGetRequest(productsResource);
};

const getUnSellingPrices = (payload: IGetProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetUnSellingPrices(payload);
    return apiGateway.doGetRequest(productsResource);
};

const getPausedProducts = (payload: IGetProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetPausedProducts(payload);
    return apiGateway.doGetRequest(productsResource);
};

const updatePrice = (payload: IUpdatePricePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.UpdatePrice();
   
    return apiGateway.doPutRequest(productResource, payload);
};

export const setActivePrice = (payload: ISetActivePricePayload): Promise<ResponseModel<APIResponse<null>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SetActivePrice();
    return apiGateway.doPutRequest(productResource, payload);
};

const setDiscontinuedPrice = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SetDiscontinuedPrice();
   
    return apiGateway.doPutRequest(productResource, payload);
};

const getActiveCustomers = (payload: IActiveCustomersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetActiveCustomers(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const getOverrideDisplay = (payload: IActiveCustomersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetOverrideDisplay(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const updatePromotion = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const promotionResource = SitecoreResource.Product.UpdatePromotion();
   
    return apiGateway.doPutRequest(promotionResource, payload);
};

const createPrice = (payload: ICreatePricePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.CreatePrice();
   
    return apiGateway.doPostRequest(productResource, payload);
};

const getPriceDetail = (id: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetPriceDetail(id);
    return apiGateway.doGetRequest(productsResource);
};

const getProductSettings = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetProductSettings(payload);
    return apiGateway.doGetRequest(productsResource);
};

const getVariantDetail = (id: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetVariantDetail(id);
    return apiGateway.doGetRequest(productsResource);
};

const deletePrice = (payload: IGetPriceDetailPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.DeletePrice(payload.id);
   
    return apiGateway.doDeleteRequest(productResource);
};

const getPriceCustomerGroups = (id: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productsResource = SitecoreResource.Product.GetPriceCustomerGroups(id);
    return apiGateway.doGetRequest(productsResource);
};

const createCustomerGroupPrice = (payload: ICreateCustomerGroupPricePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.CreateCustomerGroupPrice();
   
    return apiGateway.doPostRequest(productResource, payload);
};

const settingCustomProduct = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SettingCustomProduct();
   
    return apiGateway.doPostRequest(productResource, payload);
};

const deleteCustomerGroupPrice = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.DeleteCustomerGroupPrice(payload);
   
    return apiGateway.doDeleteRequest(productResource);
};

const updateCustomerGroupPrice = (payload: IUpdateCustomerGroupPricePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.UpdateCustomerGroupPrice();
   
    return apiGateway.doPutRequest(productResource, payload);
};

const disablePriceForGroup = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.DisablePriceForGroup();
   
    return apiGateway.doPutRequest(productResource, payload);
};

const exportPrices = (payload: IExportPricePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ExportPrices(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const exportPricesV2 = (payload: IExportPricePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ExportPricesV2(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const exportVariants = (payload: IExportVariantsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ExportVariants(payload);
   
    return apiGateway.doGetRequest(productResource);
};

export const getFileImportPrice = (payload: IGetFilePayload): Promise<ResponseModel<APIResponse<string>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetFileImportPrice(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(productResource);
};

const importVariants = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ImportVariants();
   
    return apiGateway.doPostRequest(productResource,payload);
};

const importPricesHistory = (payload: IImportPricesHistoryPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ImportPricesHistory(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const importPrices = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ImportPrices();
   
    return apiGateway.doPostRequest(productResource, payload);
};

const setProductVisible = (payload: ISetProductVisiblePayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SetProductVisible();
   
    return apiGateway.doPutRequest(productResource, payload);
};

const setOverrideDisplay = (payload: ISetOverrideDisplayPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SetOverrideDisplay();
   
    return apiGateway.doPutRequest(productResource, payload);
};
const setProductPriority = (payload: ISetProductPriorityPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SetProductPriority();
   
    return apiGateway.doPutRequest(productResource, payload);
};

const setColorVariant = (payload: ISetColorVariantPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.SetColorVariant(payload);
   
    return apiGateway.doPutRequest(productResource);
};

const importAlarmProducts = (payload: ISetOverrideDisplayPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const customerResource = SitecoreResource.Product.ImportAlarmProducts();

    return apiGateway.doPostRequest(customerResource, payload);
};

const getProductsUploadHistory = (payload: IGetProductsUploadHistoryPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetUploadProducts(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const getDetailError = (payload: IGetProductsUploadHistoryPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetDetailError(payload);
   
    return apiGateway.doGetRequest(productResource);
};

const getAlarmProductsUploadFile = (payload: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetAlarmUploadProductsFile(payload);
   
    return apiGateway.doGetRequest(productResource);
};

export const getDeliveryTime = (payload: GetDeliveryTimePayload): Promise<ResponseModel<APIResponse<GetDeliveryTimeData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetDeliveryTime(payload);
    return apiGateway.doGetRequest(productResource);
}

export const getFileImportDeliveryTime = (payload: string): Promise<ResponseModel<APIResponse<string>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetAlarmUploadProductsFile(payload);
    return apiGateway.doGetRequest(productResource);
};

export const getImportDeliveryHistory = (payload: GetImportDeliveryHistoryPayload): Promise<ResponseModel<APIResponse<GetImportDeliveryData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.GetImportDeliveryHistory(payload);
    return apiGateway.doGetRequest(productResource);
}

export const importDeliveryTime = (payload: FormData): Promise<ResponseModel<APIResponse<{ errors: string[] }>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const productResource = SitecoreResource.Product.ImportDeliveryTime();
    return apiGateway.doPostRequest(productResource, payload);
}

export default {
    getSellingPrices,
    getDiscontinuedPrices,
    searchProducts,
    getPriceDetail,
    getProductSettings,
    getVariantDetail,
    getUnSellingPrices,
    getPausedProducts,
    exportPrices,
    exportPricesV2,
    exportVariants,
    getFileImportPrice,
    importVariants,
    importPricesHistory,
    importPrices,
    updatePrice,
    setActivePrice,
    setDiscontinuedPrice,
    getActiveCustomers,
    getOverrideDisplay,
    updatePromotion,
    createPrice,
    deletePrice,
    getPriceCustomerGroups,
    createCustomerGroupPrice,
    settingCustomProduct,
    deleteCustomerGroupPrice,
    updateCustomerGroupPrice,
    disablePriceForGroup,
    setProductVisible,
    setOverrideDisplay,
    setProductPriority,
    setColorVariant,
    importAlarmProducts,
    getProductsUploadHistory,
    getAlarmProductsUploadFile,
    getDetailError
};
