import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, createInvoiceFail, createInvoiceSuccess, getBulkUploadHistoriesFail, getBulkUploadHistoriesSuccess, publishInvoicesFail, publishInvoicesSuccess, searchInvoicesFail, searchInvoicesSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import { CreateInvoiceUseCase, PublishInvoicesUseCase } from "../../../../domain/usecases/invoices";
import { IGetBulkUploadHistoriesPayload, IPublishInvoicesPayload } from "../../actions/interfaces/invoices.types";
import GetBulkUploadHistoriesUseCase from "../../../../domain/usecases/invoices/GetBulkUploadHistoriesUseCase";


export const getBulkUploadHistoriesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.GET_BULK_UPLOAD_HISTORIES),
        exhaustMap((action: IAction<IGetBulkUploadHistoriesPayload>) => {

            return new Observable(obs => {
                const { payload } = action;
                if(payload == null){
                    obs.next(getBulkUploadHistoriesFail('Invalid payload'));
                    obs.complete();
                }
                else{
                    const usecase = new GetBulkUploadHistoriesUseCase(payload);
                    usecase.execute().then((responses: any) => {
                        let data = responses;
                        obs.next(getBulkUploadHistoriesSuccess(data.data));
                        obs.complete();
                    }).catch(error => {
                        obs.next(getBulkUploadHistoriesFail(error.message));
                        notifyError(error.message);
                        obs.complete();
                    });
                }
            });
        })
    );


