import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { SetDiscontinuedPriceUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, setDiscontinuedPriceSuccess, setDiscontinuedPriceFail } from "../../actions/products/product.actions";


export const setDiscontinuedPriceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.SET_DISCONTINUED_PRICE),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SetDiscontinuedPriceUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    if(state$.value.productsReducer.setDiscontinuedPriceReducer.params.discontinued){
                        notifySuccess('商品を終売に変更しました');
                    }else{
                        notifySuccess('終売商品が復活しました');
                    }
                    obs.next(setDiscontinuedPriceSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(setDiscontinuedPriceFail(error.message));
                    obs.complete();
                });
            });
        })
    );


