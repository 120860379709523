import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import NotificationRepository from '../../../data/repository/notification';
import { notifyError, notifySuccess } from '../../../utils/toast';

export default class SaveNotificationUseCase implements IUseCase {
    private notificationRepository: NotificationRepository;

    /**
     *
     */
    constructor(public payload: any) {
        this.notificationRepository = new NotificationRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.notificationRepository.saveNotificationRepository(this.payload).then((response: any) => {
                if (response.data.success) {
                    // notifySuccess('Save notification success !');
                    resolve(response.data);
                } else {
                    // notifyError(`Save notification fail: ${response.data.errorMessage}`);
                    reject(ResponseModel.createError(500, response.data.errorMessage));
                }
            }).catch(reject);
        });

    };

}
