import {SitecoreResource} from '../../../gateway/api/resource';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import { IDeleteTempProductPayload, ISaveTempProductPayload, ISearchProductsPayload, ISearchTempProductsPayload } from '../../../../presentation/redux/actions/interfaces/tempProduct.types';

const searchProducts = (payload: ISearchProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.SearchProducts(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(resource);
};

const saveTempProduct = (payload: ISaveTempProductPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.SaveTempProduct(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(resource, payload);
};

const deleteTempProduct = (payload: IDeleteTempProductPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.DeleteTemporaryProduct(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doDeleteRequest(resource);
};

const searchTempProducts = (payload: ISearchTempProductsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.SearchTempProducts(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(resource);
};

const getCategories = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.GetCategories();
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(resource);
};

const getBrands = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.GetBrands();
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doGetRequest(resource);
};

const importBatchProducts = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const resource = SitecoreResource.TempProduct.ImportBatchProducts(payload);
    //apiGateway._endpoint = 'https://localhost:5001'
    return apiGateway.doPostRequest(resource,payload);
};

export default {
    searchProducts,
    saveTempProduct,
    deleteTempProduct,
    searchTempProducts,
    getCategories,
    getBrands,
    importBatchProducts
};
