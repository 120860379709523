import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, cancelFaxSuccess, cancelFaxFail } from "../../actions/orders/order.actions";
import { CancelFaxUseCase} from "../../../../domain/usecases/order";

export const cancelFaxEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.CANCEL_FAX),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CancelFaxUseCase(payload);
                usecase.execute().then((responses: any) => {
                    var data = responses.data;
                    obs.next(cancelFaxSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(cancelFaxFail(error.message));
                    obs.complete();
                });
            });
        })
    );


