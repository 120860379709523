import {gql} from 'graphql-request';

export const getNotAssignStockStatusQuery = () => {
    return gql`
    {
      result: labels(where: {category: "Stock"}) {
        id
        name
        category
      }
    }`;
  }

 

  