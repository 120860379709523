import { combineReducers } from "redux";
import { getSalesRepresentativesReducer } from "./getSalesRepresentativesReducer";
import { addSaleRepresentativeReducer } from "./addSaleRepresentativeReducer";
import { importSaleReducer } from "./importSaleReducer";
import { deleteSalesReducer } from "./deleteSalesReducer";
import { updateSaleReducer } from "./updateSaleReducer";

export const saleReducer = combineReducers({
  getSalesRepresentativesReducer,
  addSaleRepresentativeReducer,
  importSaleReducer,
  deleteSalesReducer,
  updateSaleReducer,
});
