import { combineReducers } from "@reduxjs/toolkit";
import { loginReducer } from "./loginReducer";
import { getUsersReducer } from "./getUsersReducer";
import { addUserReducer } from "./addUserReducer";
import { getUserDetailReducer } from "./getUserDetailReducer";
import { getUserDetailStorageReducer } from "./getUserDetailStorageReducer";
import { getSakayaInfoReducer } from "./getSakayaInfoReducer";
import { updateSakayaInfoReducer } from "./updateSakayaInfoReducer";
import { updatePasswordReducer } from "./updatePasswordReducer";
import { resetPasswordReducer } from "./resetPasswordReducer";
import { deleteUserReducer } from "./deleteUserReducer";
import { updateUserReducer } from "./updateUserReducer";

export const usersReducer = combineReducers({
    loginReducer,
    getUsersReducer,
    addUserReducer,
    getUserDetailReducer,
    getUserDetailStorageReducer,
    getSakayaInfoReducer,
    updateSakayaInfoReducer,
    updatePasswordReducer,
    resetPasswordReducer,
    deleteUserReducer,
    updateUserReducer
});