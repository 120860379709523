import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect, useLocation,
} from 'react-router-dom';
import { ToastContainer } from './utils/toast';
import AccessibleNavigationAnnouncer from './presentation/components/AccessibleNavigationAnnouncer';
import PrivateRoute from './presentation/components/login/PrivateRoute';
import { useDispatch } from 'react-redux';
import { initApplication } from './presentation/redux/actions/general/appInitiation';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {triggerPageView} from "./utils/plausible";

const firebaseConfig = {
  apiKey: "AIzaSyC80QSIcIMDOhPUIzm8NFA2bUyuTu7bZew",
  authDomain: "urry-order-dev.firebaseapp.com",
  projectId: "urry-order-dev",
  storageBucket: "urry-order-dev.appspot.com",
  messagingSenderId: "732616166580",
  appId: "1:732616166580:web:135980838c9a111aec9be1",
  measurementId: "G-8NJSLSL17M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Layout = lazy(() => import('./presentation/layout/Layout'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const ForgetPassword = lazy(() => import('./pages/ForgotPassword'));
const UpdatePassword = lazy(() => import('./pages/UpdatePassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initApplication());

  },[]);

  return (
    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/update-password" component={UpdatePassword} />
          <Route path="/reset-password/:token/:email" component={ResetPassword} />

          <PrivateRoute>
            {' '}
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
};

export default App;
