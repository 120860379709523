import { IAction, IState } from "../../../../shared/interfaces/common";
import { SaleActionTypes } from "../../actions/sales/sale.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = {
  ...ReducerHelper.baseReducer(),
};

export const updateSaleReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case SaleActionTypes.UPDATE_SALE: {
      return {
        ...ReducerHelper.baseProcessRequest(state, action),
      };
    }

    case SaleActionTypes.UPDATE_SALE_SUCCESS: {
      return {
        ...ReducerHelper.baseProcessSuccess(state, action),
      };
    }

    case SaleActionTypes.UPDATE_SALE_FAIL: {
      return {
        ...ReducerHelper.baseProcessFailed(state, action),
      };
    }

    case SaleActionTypes.RESET_UPDATE_STATE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};
