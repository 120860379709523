import { IAction, IState } from "../../../../shared/interfaces/common";
import { ProductActionTypes } from "../../actions/products/product.actions";
import ReducerHelper from "../../rootReducers/helper";

// const initialState: IState<string> = ReducerHelper.baseReducer();

const initialState: IState<string> & { showMessage: boolean } = {
    ...ReducerHelper.baseReducer(),
    showMessage: false,
  };

export const importAlarmProductsReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case ProductActionTypes.IMPORT_ALARM_PRODUCTS: {
            return {
                ...ReducerHelper.baseProcessRequest(state, action),
                showMessage: false,
            };
        }
        case ProductActionTypes.IMPORT_ALARM_PRODUCTS_SUCCESS: {
            return {
                ...ReducerHelper.baseProcessSuccess(state, action),
                showMessage: true,
            };
        }
        case ProductActionTypes.IMPORT_ALARM_PRODUCTS_FAIL: {
            return {
                ...ReducerHelper.baseProcessFailed(state, action),
                showMessage: true
            };
        }

        case ProductActionTypes.RESET_ALARM_PRODUCTS: {
            return initialState
        }
        default: {
            return state;
        }
    }
}
