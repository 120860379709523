import { ofType } from "redux-observable";
import {
  CustomerActionTypes,
  getCustomerHidePriceUploadHistoryFail,
  getCustomerHidePriceUploadHistorySuccess,
} from "../../actions/customers/customer.actions";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { Observable } from "rxjs";
import { GetCustomerHidePriceUploadHistoryUseCase } from "../../../../domain/usecases/customer";

export const getCustomerHidePriceUploadHistoryEpic = (
  action$: any,
  state$: any
) =>
  action$.pipe(
    ofType(CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new GetCustomerHidePriceUploadHistoryUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(getCustomerHidePriceUploadHistorySuccess(data));

            obs.complete();
          })
          .catch((error) => {
            obs.next(getCustomerHidePriceUploadHistoryFail(error.message));

            obs.complete();
          });
      });
    })
  );
