import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import GetSakayaInfoUseCase from "../../../../domain/usecases/users/getSakayaInfo";
import { IAction } from "../../../../shared/interfaces/common";
import { UserActionTypes, getSakayaInfoSuccess, getSakayaInfoFail } from "../../actions/users/user.actions";


export const getSakayaInfoEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.GET_SAKAYA_INFO),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const usecase = new GetSakayaInfoUseCase();
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getSakayaInfoSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getSakayaInfoFail(error.message));
                    obs.complete();
                });
            });
        })
    );


