import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import ProductRepository from '../../../data/repository/product';
import ValidationResult from '../../../models/validationResult';

export default class GetPriceCustomerGroupsUseCase implements IUseCase {
    private productRepository: ProductRepository;

    /**
     *
     */
    constructor(public id: string) {
        this.productRepository = new ProductRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.productRepository.getPriceCustomerGroupsRepository(this.id).then((response: any) => {
                if (response.data.data) {
                    resolve(response.data);
                } else {
                    reject(ResponseModel.createError(500, 'Price Data is null'));
                }
            }).catch(reject);
        });

    };

}
