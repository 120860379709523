import { IAction, IState } from "../../../../shared/interfaces/common";
import { NotificationActionTypes } from "../../actions/notification/notification.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const getDraftRecordReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case NotificationActionTypes.GET_DRAFT_RECORD: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case NotificationActionTypes.GET_DRAFT_RECORD_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case NotificationActionTypes.GET_DRAFT_RECORD_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
        default: {
            return state;
        }
    }
}
