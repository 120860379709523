import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { CreateAddressUseCase } from "../../../../domain/usecases/customer";
import { IAction } from "../../../../shared/interfaces/common";
import { CustomerActionTypes, importHorecaInformationFail, importHorecaInformationSuccess } from "../../actions/customers/customer.actions";

export const importHorecaInformationEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(CustomerActionTypes.IMPORT_HORECA_CODE),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CreateAddressUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(importHorecaInformationSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(importHorecaInformationFail(error.message));
                    obs.complete();
                });
            });
        })
    );


