import { ofType } from "redux-observable";
import { IAction } from "../../../../shared/interfaces/common";
import { exhaustMap } from "rxjs/operators";
import { Observable } from "rxjs";
import {
  SaleActionTypes,
  addSaleRepresentativeFail,
  addSaleRepresentativeSuccess,
  onAddSaleSuccess,
} from "../../actions/sales/sale.actions";
import { AddSaleRepresentativeUseCase } from "../../../../domain/usecases/sales";

export const addSaleRepresentativeEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SaleActionTypes.ADD_SALES_REPRESENTATIVES),
    exhaustMap((action: IAction<any>): any => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new AddSaleRepresentativeUseCase(payload);

        useCase
          .execute()
          .then((response: any) => {
            const data = response;

            obs.next(onAddSaleSuccess(data));
            obs.next(addSaleRepresentativeSuccess(data));
            obs.complete();
          })
          .catch((error) => {
            obs.next(addSaleRepresentativeFail(error.message));
            obs.complete();
          });
      });
    })
  );
