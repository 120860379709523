import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import AddUserUseCase from "../../../../domain/usecases/users/addUser";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { UserActionTypes, addUserSuccess, addUserFail } from "../../actions/users/user.actions";


export const addUserEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.ADD_USER),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new AddUserUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    // console.log('adduseredpi===',data);
                    
                    notifySuccess('新しいユーザーの追加完了 !');
                    obs.next(addUserSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(addUserFail(error.message));
                    obs.complete();
                });
            });
        })
    );


