import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { NotificationActionTypes, deleteSegmentFail, deleteSegmentSuccess} from "../../actions/notification/notification.actions";
import { DeleteSegmentUseCase } from "../../../../domain/usecases/notification";
import { notifySuccess } from "../../../../utils/toast";

export const deleteSegmentEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(NotificationActionTypes.DELETE_SEGMENT),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new DeleteSegmentUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(deleteSegmentSuccess(data.data));
                    obs.complete();
                    notifySuccess("Delete segment success !");
                }).catch(error => {
                    obs.next(deleteSegmentFail(error.message));
                    obs.complete();
                });
            });
        })
    );


