import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { NotificationActionTypes, getSettingNotificationsSuccess, getSettingNotificationsFail } from "../../actions/notification/notification.actions";
import { GetSettingNotificationsUseCase } from "../../../../domain/usecases/notification";

export const getSettingNotificationsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(NotificationActionTypes.GET_SETTING_NOTIFICATIONS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const usecase = new GetSettingNotificationsUseCase();
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getSettingNotificationsSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getSettingNotificationsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


