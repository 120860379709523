import { IAction, IState } from "../../../../shared/interfaces/common";
import { CustomerActionTypes } from "../../actions/customers/customer.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const getCustomerHidePriceFileReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case CustomerActionTypes.GET_HIDE_PRICE_FILE: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case CustomerActionTypes.GET_HIDE_PRICE_FILE_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case CustomerActionTypes.GET_HIDE_PRICE_FILE_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    case CustomerActionTypes.RESET_GET_HIDE_PRICE_FILE_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
