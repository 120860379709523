import Type from '../../type';
import {getApiController} from '..';
import {IResource} from '../../../../../shared/interfaces/common/resource';
import {
    IGetOrdersPayload,
    IExportOrdersPayload,
    IUpdateOrderPayload,
    IExportPurchaseOrdersPayload,
    IGetRevenueInformationPayload,
    GetReturnRequestsPayload,
    ExportReturnRequestByIdsPayload,
    ExportReturnRequestByScopePayload,
} from '../../../../../presentation/redux/actions/interfaces/order.types';

const GetOrder = (orderId: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/Detail?orderId=${orderId}&includeProducts=true&includeShippingAddresses=true&includeShipments=true`
});

const GetOrders = (payload: IGetOrdersPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0?downloadStatus=${payload.downloadStatus != undefined ? payload.downloadStatus : ''}&page=${payload.page}&pageSize=${payload.pageSize}&customerId=${payload.customerId}&includeProducts=true&includeShippingAddresses=true&includeShipments=true&startDate=${payload.startDate}&endDate=${payload.endDate}&status=${payload.status}&keyword=${payload.keyword}&zone=${payload.zone}${payload.hasCancelledProduct == null ? '' : '&hasCancelledProduct=' + payload.hasCancelledProduct}&sakayaId=${payload.sakayaId ?? ''}&filterDateType=${payload.filterDateType == null ? 1 : payload.filterDateType}&timeStart=${payload.timeStart}&timeEnd=${payload.timeEnd}`
});

const ExportOrdersHistories = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ExportOrdersHistories?&page=${payload.page}&pageSize=${payload.pageSize}&startDate=${payload.startDate}&endDate=${payload.endDate}&status=${payload.status ?? ''}&sakayaId=${payload.sakayaId ?? ''}&isFTP=${payload.isFTP ?? null}`
});

const GetRevenueInformation = (payload: IGetRevenueInformationPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/RevenueInformation?sakayaId=${payload.sakayaId}&startDate=${payload.startDate}&endDate=${payload.endDate}`
});

const ChangeOrderStatus = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/UpdateOrderStatus`
});

const ChangeOrderProductsStatus = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ChangeOrderProductsStatus`
});

const ExportOrders = (payload: IExportOrdersPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ExportOrders?customerId=${payload.customerId}&includeProducts=true&includeShippingAddresses=true&includeShipments=true&startDate=${payload.startDate}&endDate=${payload.endDate}&status=${payload.status}&keyword=${payload.keyword}&zone=${payload.zone}&filterDateType=${payload.filterDateType == null ? 1 : payload.filterDateType}&includeExported=${payload.includeExported}&isCsvType=${payload.isCsvType}&setOrderAccepted=${payload.setOrderAccepted}`
});

const ExportOrdersV2 = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ExportOrdersV2?timeStart=${payload.timeStart}&timeEnd=${payload.timeEnd}&codes=${payload.codes}&startDate=${payload.startDate}&endDate=${payload.endDate}&status=${payload.status}&exportRange=${payload.exportRange}&exportContentType=${payload.exportContentType}&setOrderAccepted=${payload.setOrderAccepted}&sakayaId=${payload.sakayaId ?? ''}`
});

const ExportSales = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/report/v1.0/ExportSaleTracking`
});

const UpdateOrder = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/UpdateOrder`
});

const UpdateOrderProducts = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/UpdateOrderProductsStatus`
});

const ExportPurchaseOrders = (payload: IExportPurchaseOrdersPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ExportPurchaseOrders?ids=${payload.ids}&returnBytes=false&exportSummary=${payload.exportSummary}&setOrderAccepted=${payload.setOrderAccepted}`
});

const ExportSakayasInfoSummary = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ExportSakayasInfoSummary`
});

const ResendOrderConfirmation = (payload: any, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ResendOrderConfirmation?id=${payload.id}`
});

const GetPurchasedProducts = (customerId: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/GetPurchasedProducts?customerId=${customerId}`
});

const SendProductRejectedNotification = (orderId: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/SendProductRejectedNotification?id=${orderId}`
});

const SaveFirebaseToken = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/SaveFirebaseToken`
});

const getSakayasInfoSummary = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/SakayasInfoSummary`
});

const GetOrderSummary = (payload: IGetOrdersPayload, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/OrderSummary?customerId=${payload.customerId}&startDate=${payload.startDate}&endDate=${payload.endDate}&status=${payload.status}&keyword=${payload.keyword}&zone=${payload.zone}&sakayaId=${payload.sakayaId}`
});

const GetReturnRequests = ({ keyword, statuses, zone, startDate, endDate, page, pageSize }: GetReturnRequestsPayload, storeView?: string): IResource => {
    let Path = `${getApiController(storeView)}/management/order/v1.0/SearchProductReturnRequests?page=${page}&pageSize=${pageSize}`;

    if (keyword) Path += `&keyword=${keyword}`;
    if (statuses?.length) Path += `&status=[${statuses.join(",")}]`;
    if (zone) Path += `&zone=${zone}`;
    if (startDate) Path += `&startDate=${startDate}`;
    if (endDate) Path += `&endDate=${endDate}`;

    return { 
        Type: Type.Customer,
        Path,
    };
};

const GetReturnRequestById = (id: string , storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/GetReturnRequestDetail?returnId=${id}`,
});

const ExportReturnRequestByIds = ({ ids, exportSummary = true, format, returnBytes }: ExportReturnRequestByIdsPayload, storeView?: string): IResource => {
    let Path = `${getApiController(storeView)}/management/order/v1.0/ExportReturnRequestPdf?ids=${ids.join(",")}`;
    if (exportSummary) Path += "&exportSummary=true";
    if (format === "csv") Path += "&isExcelOrCsv=2";
    if (format === "xlsx") Path += "&isExcelOrCsv=1";
    if (returnBytes) Path += "&returnBytes=true";

    return {
        Type: Type.Customer,
        Path,
    }
}

const ExportReturnRequestByScope = ({ format, returnBytes, scope, keyword, statuses, zone, startDate, endDate }: ExportReturnRequestByScopePayload, storeView?: string): IResource => {
    let Path = `${getApiController(storeView)}/management/order/v1.0/ExportReturnRequestExcel?`;
    if (format === "csv") Path += "&IsCsvType=true";
    if (format === "pdf") Path += "&IsPdfType=true";
    if (returnBytes) Path += "&returnBytes=true";
    if (keyword) Path += `&keyword=${keyword}`;
    if (statuses?.length) Path += `&status=[${statuses.join(",")}]`;
    if (zone) Path += `&zone=${zone}`;
    if (startDate) Path += `&startDate=${startDate}`;
    if (endDate) Path += `&endDate=${endDate}`;
    Path += "&exportSummary=true";
    Path += `&IncludeExported=${ scope === "all" ? "true" : "false" }`;

    return {
        Type: Type.Customer,
        Path,
    };
};

const SendEmailReturnRequest = (id: string, storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/SendEmailNotiRejectReturn?returnId=${id}`,
});

const UpdateReturnRequestStaffNotes = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/UpdateReturnRequest`
});

const UpdateReturnRequestStatus = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/UpdateReturnRequestStatus`
})

const UpdateReturnRequestProductsStatus = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ChangeReturnProductStatus`
});

const GenerateProductsPdf = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/GenerateProductsPdf`
});

const CreateSelectedCollection = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/CreateSelectedCollection`
});

const DeleteCollectedOption = (id: string,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/DeleteCollectedOption?id=${id}`
});

const GetProductsSelected = (customerId: string,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/GetProductsSelected?customerId=${customerId}`
});

const ScanFaxImages = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/ScanFaxImages`
});

const GetFaxFiles = (includeCompleted:boolean,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/GetFaxFiles?includeCompleted=${includeCompleted}`
});

const FaxContent = (faxId:string,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/FaxDetail?faxId=${faxId}`
});

const CancelFax = (faxId:string,storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/CancelFax?faxId=${faxId}`
});

const CreateOrder = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/ocr/v1.0/CreateOrder`
});

const ExportOrdersPos = (storeView?: string): IResource => ({
    Type: Type.Customer,
    Path: `${getApiController(storeView)}/management/order/v1.0/ExportOrdersForPos`
});

const resources = {
    GetOrder,
    GetOrders,
    ExportOrdersHistories,
    ChangeOrderStatus,
    ChangeOrderProductsStatus,
    ExportOrders,
    ExportOrdersV2,
    ExportSales,
    UpdateOrder,
    UpdateOrderProducts,
    GetRevenueInformation,
    ExportPurchaseOrders,
    ExportSakayasInfoSummary,
    ResendOrderConfirmation,
    GetPurchasedProducts,
    SendProductRejectedNotification,
    SaveFirebaseToken,
    GetOrderSummary,
    GetReturnRequests,
    GetReturnRequestById,
    ExportReturnRequestByIds,
    ExportReturnRequestByScope,
    SendEmailReturnRequest,
    UpdateReturnRequestStaffNotes,
    UpdateReturnRequestStatus,
    UpdateReturnRequestProductsStatus,
    getSakayasInfoSummary,
    GenerateProductsPdf,
    CreateSelectedCollection,
    DeleteCollectedOption,
    GetProductsSelected,
    ScanFaxImages,
    CreateOrder,
    GetFaxFiles,
    FaxContent,
    CancelFax,
    ExportOrdersPos,
};

export default resources;
