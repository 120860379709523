import { campaignStatus, emailStatus } from "../../../../shared/constants";
import { Sakaya } from "./users.types";

export type SellingPrice = {
  defaultPrice: number;
  discontinued: boolean;
  id: string;
  image: string;
  isCustomPrice: boolean;
  packageType: string;
  pricePerItem: number;
  priority: any | null;
  productId: string;
  productIdBySakaya: string;
  productName: string;
  quantityPerPackage: number;
  sakayaId: string;
  sakayaName: string;
  salePrice: number;
  size: string;
  stockStatus: string;
  type: string;
  variantId: string;
  variantName: string;
  variantPriority: number;
};

export type GetSellingPrices = {
  excludedPriceIds: string[];
  prices: SellingPrice[];
  total: number;
};

export type PromotionsMaterialBrand = {
  id: string;
  name: string;
  nameForSearch?: string;
  priority: number;
};

export type PromotionsMaterial = {
  brand?: PromotionsMaterialBrand;
  createdAt: string;
  description: string;
  id: string;
  image?: {
    url: string;
    mimeType: string;
    width?: number;
    height?: number;
    size?: number;
    fileName?: string;
  };
  manufacturer?: string;
  materialName: string;
  materialValue?: number;
  productCode: string;
  quantity: number;
  publishedAt: string;
  sakayaId: string;
};

export type PromotionsMaterialDetail = Omit<PromotionsMaterial, "sakayaId"> & {
  sakaya: Sakaya;
};

export type PromotionsReward = {
  quantity: number;
  stockQuantity?: number;
} & (
  | {
      type: CampaignRewardType.Price;
      product: RewardUnit;
      material: undefined;
    }
  | {
      type: CampaignRewardType.GiftMaterial;
      product: undefined;
      material: RewardUnit;
    }
);

export type CreatePromotionsMaterialsPayload = {
  brand?: string;
  description: string;
  image?: File;
  manufacturer?: string;
  materialName: string;
  materialValue?: number;
  quantity: number;
  sakayaId: string;
};

export type UpdatePromotionsMaterialsPayload = {
  id: string;
} & CreatePromotionsMaterialsPayload;

// TODO: typing
export type CreatePromotionsRewardsPayload = any;

export enum CampaignType {
  SampleAndGift = "0",
  Discount = "1",
  MileagesAndLoyalty = "2",
}

export enum PromotionType {
  FreeSampleOrMaterial = "0",
  OrderDiscount = "1",
  ProductDiscount = "2",
  FreeShipping = "3",
}

export enum ConditionType {
  OrderIncludeProduct = "0",
  OrderIncludeBrandManufacturer = "1",
  OrderIncludeProductTag = "2",
  CartTotal = "3",
}

export enum ProductConditionType {
  ByProductOrderTotal = "BY_PRODUCT_ORDER_TOTAL",
  ByQuantity = "BY_QUANTITY",
}

export type CampaignProductCondition = {
  priceId: string;
  type: ProductConditionType;
  productTotalPrice: number;
  quantity: number;
};

export enum CampaignRewardType {
  GiftMaterial = "GiftMaterial",
  Price = "Price",
}

export type CampaignReward = {
  id: string;
  type: CampaignRewardType;
  quantity: number;
  stockQuantity?: number;
};

export type PromotionsSalesPayload = {
  sakayaId: string;
  name: string;
  campaignType: CampaignType;
  startDate?: string;
  expirationDate?: string;
  title: string;
  description: string;
  pdf?: File;
  disclaimer?: string;
  promotionType: PromotionType;
  conditionType: ConditionType;
  // JSON stringify of conditon array
  conditions: string;
  // JSON stringify of condition array
  rewards: string;
  usageLimit?: number;
  isOrReward: boolean;
  isOrCondition: boolean;
  isDraft: boolean;
  // JSON stringify of id array
  includeSegments: string;
  // JSON stringify of id array
  excludeSegments: string;
  // JSON stringify of id array
  includeUsers: string;
  // JSON stringify of id array
  excludeUsers: string;
  emailNotification?: boolean;
  pushNotification?: boolean;
  notificationScheduleDate?: string;
  // use for update
  id?: string;
  isDetachedPdf?: boolean;
};

export type GetPromotionsMaterialsPayload = {
  keyword?: string;
  maxValue?: number;
  minValue?: number;
  page: number;
  pageSize: number;
  sakayaId: string;
  isFromLibrary?: boolean
};

// TODO: typing
export type GetPromotionsRewardsPayload = any;

// TODO: typing
export type GetPromotionsSalesPayload = {
  page: number;
  pageSize: number;
  keyword: string;
  sakayaId?: string;
  campaignStatus?: string;
  conditionType?: string;
  promotionType?: string;
  startDate?: string;
  expirationDate?: string;
  emailStatus?: string;
  pushStatus?: string;
  sendingTime?: string;
  timeRangeType?: TimeRangeType;
  notificationScheduleDateStart?: string;
  notificationScheduleDateEnd?: string;
};

export type SearchBrandsPayload = {
  keyword?: string;
  page: number;
  pageSize: number;
};

export type PromotionBrand = {
  id: string;
  name?: string;
  label?: string;
};

export type PromotionsSales = {
  id: string;
  name: string;
  pdfFile: {
    fileName: string;
    url: string;
    size: number;
  };
  disclaimer: string;
  campaignStatus: keyof typeof campaignStatus;
  conditionType?: ConditionType;
  promotionType?: PromotionType;
  description: string;
  sakaya: Sakaya;
  expirationDate: string;
  startDate: string;
  type: CampaignType;
  validationRule: ValidationRule;
  voucher: Voucher;
  title: string;
  excludeCustomers: string[];
  excludeCustomersInfor: Segment[];
  excludeSegments: string[];
  excludeSegmentsInfor: Segment[];
  includeCustomers: string[];
  includeCustomersInfor: Segment[];
  includeSegments: string[];
  includeSegmentsInfor: Segment[];
  totalAudience: number;
  notificationSchedule: string | null;
  conditions: CampaignProduct[];
  emailNotification: boolean;
  pushNotification: boolean;
  pushStatus: keyof typeof emailStatus;
  emailStatus: keyof typeof emailStatus;
};

export type PromotionSalesResponse = {
  hasMore: boolean;
  result: PromotionsSales[];
  total: number;
};

type ValidationRule = {
  link: {
    conditions: Condition[];
    id: string;
    isOr: boolean;
    name: string;
  };
};

type Condition = {
  conditionType: ProductConditionType;
  conditionValue: number;
  unit: ConditionUnit;
};

export type ConditionUnit = {
  id: string;
  image: string | null;
  name: string;
  quantityPerPackage: number;
  size: string | null;
  stockQuantity: number;
  type: string | null;
  variantName: string | null;
};

export type RewardUnit = ConditionUnit;

type Voucher = {
  usageLimit: number;
  voucherDetail: {
    id: string;
    name: string | null;
    quantity: number;
    unit: RewardUnit;
    redeemableRewardQuantity: number;
  }[];
  isOr: boolean;
};

type Segment = {
  id: string;
  name: string;
};
export type GetAudienceSizePayload = {
  includeSegments?: string;
  excludeSegments?: string;
  includeCustomers?: string;
  excludeCustomers?: string;
};

export type GetAudienceSizeResponse = {
  result: number;
};

export type CampaignProduct = {
  id: string;
  image: string;
  minOrder: number | null;
  name: string;
  quantity: number | null;
  quantityPerPackage: number;
  size: string;
};

export type SearchSegmentResponse = {
  data: {
    data: Segment[];
    limitSegments: number;
  };
  total: number;
};

export type SearchSegmentsPayload = {
  keyword?: string;
  page: number;
  pageSize: number;
};

export enum TimeRangeType {
  CAMPAIGN = "campaign",
  NOTIFICATION = "notification",
}

export type UpdateStatusPayload = {
  id: string;
  campaignStatus: keyof typeof campaignStatus
}
