import { combineEpics } from "redux-observable";
import { saveNotificationEpic } from "./saveNotificationEpic";
import { savePartitalNotificationEpic } from "./savePartitalNotificationEpic";
import { registerPriceChangesEpic } from "./registerPriceChangesEpic";
import { pushNotificationEpic } from "./pushNotificationEpic";
import { searchBrandsEpic } from "./searchBrandsEpic";
import { searchBannersEpic } from "./searchBannersEpic";
import { searchNotificaionsEpic } from "./searchNotificaionsEpic";
import { searchPriceChangesEpic } from "./searchPriceChangesEpic";
import { getCategoriesEpic } from "./getCategoriesEpic";
import { searchTagsEpic } from "./searchTagsEpic";
import { deleteTagsEpic } from "./deleteTagsEpic";
import { addTagsForSakayaEpic } from "./addTagsForSakayaEpic";
import { updateTagForSakayaEpic } from "./updateTagForSakayaEpic";
import { addTagForCustomerEpic } from "./addTagForCustomerEpic";
import { deleteTagForCustomerEpic } from "./deleteTagForCustomerEpic";
import { importCustomerTagsEpic } from "./importCustomerTagsEpic";
import { populateAddressEpic } from "./populateAddressEpic";
import { getOrderOfProductEpic } from "./getOrderOfProductEpic";
import { getPriceChangeLinesEpic } from "./getPriceChangeLinesEpic";
import { uploadPriceNotificationEpic } from "./uploadPriceNotificationEpic";
import { importNotificationHistoryEpic } from "./importNotificationHistoryEpic";
import { downloadDraftToFileEpic } from "./downloadDraftToFileEpic";
import { getDraftRecordEpic } from "./getDraftRecordEpic";
import { getNotificationDetailEpic } from "./getNotificationDetailEpic";
import { deleteDraftRecordEpic } from "./deleteDraftRecordEpic";
import { deleteNotificationsEpic } from "./deleteNotificationsEpic";
import { updateNotificationStatusEpic } from "./updateNotificationStatusEpic";
import { searchDiscontinuedEpic } from "./searchDiscontinuedEpic";
import { searchReadyDiscontinuedEpic } from "./searchReadyDiscontinuedEpic";
import { getDiscontinuedProductsEpic } from "./getDiscontinuedProductsEpic";
import { historyDiscontinuedProductsEpic } from "./historyDiscontinuedProductsEpic";
import { countWarningProductsEpic } from "./countWarningProductsEpic";
import { createDiscontinuedProductsEpic } from "./createDiscontinuedProductsEpic";
import { uploadDiscontinuedProductsEpic } from "./uploadDiscontinuedProductsEpic";
import { updateDiscontinueLineEpic } from "./updateDiscontinueLineEpic";
import { deleteDiscontinueLinesEpic } from "./deleteDiscontinueLinesEpic";
import { savePromotionEpic } from "./savePromotionEpic";
import { calculateAnnouncementTargetEpic } from "./calculateAnnouncementTargetEpic";
import { downloadDiscontinueLinesEpic } from "./downloadDiscontinueLinesEpic";
import { searchSegmentsEpic } from "./searchSegmentsEpic";
import { addSegmentEpic } from "./addSegmentEpic";
import { calculateAudienceSizeEpic } from "./calculateAudienceSizeEpic";
import { deleteSegmentEpic } from "./deleteSegmentEpic";
import { settingNotificationsEpic } from "./settingNotificationsEpic";
import { getSettingNotificationsEpic } from "./getSettingNotificationsEpic";

export const notificationEpic = combineEpics(
    countWarningProductsEpic,
    updateDiscontinueLineEpic,
    deleteDiscontinueLinesEpic,
    savePromotionEpic,
    calculateAnnouncementTargetEpic,
    downloadDiscontinueLinesEpic,
    saveNotificationEpic,
    savePartitalNotificationEpic,
    registerPriceChangesEpic,
    pushNotificationEpic,
    searchBrandsEpic,
    searchBannersEpic,
    searchNotificaionsEpic,
    searchPriceChangesEpic,
    getCategoriesEpic,
    searchTagsEpic,
    deleteTagsEpic,
    addTagsForSakayaEpic,
    updateTagForSakayaEpic,
    addTagForCustomerEpic,
    deleteTagForCustomerEpic,
    importCustomerTagsEpic,
    populateAddressEpic,
    getOrderOfProductEpic,
    getPriceChangeLinesEpic,
    uploadPriceNotificationEpic,
    importNotificationHistoryEpic,
    downloadDraftToFileEpic,
    getDraftRecordEpic,
    getNotificationDetailEpic,
    deleteDraftRecordEpic,
    deleteNotificationsEpic,
    updateNotificationStatusEpic,
    searchDiscontinuedEpic,
    searchReadyDiscontinuedEpic,
    getDiscontinuedProductsEpic,
    historyDiscontinuedProductsEpic,
    createDiscontinuedProductsEpic,
    uploadDiscontinuedProductsEpic,
    searchSegmentsEpic,
    addSegmentEpic,
    calculateAudienceSizeEpic,
    deleteSegmentEpic,
    settingNotificationsEpic,
    getSettingNotificationsEpic,
);
