import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import ApiHelper from "../../../../data/helper/ApiHelper";
import UpdatePromotion from "../../../../domain/usecases/products/UpdatePromotion";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, updatePromotionFail, updatePromotionSuccess } from "../../actions/products/product.actions";


export const updatePromotionEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.UPDATE_PROMOTION),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new UpdatePromotion(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    notifySuccess('プロモーションが更新されました!');
                    obs.next(updatePromotionSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(updatePromotionFail(error.message));
                    obs.complete();
                });
            });
        })
    );


