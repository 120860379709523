import { combineEpics } from "redux-observable";
import { userLoginEpic } from "./userLoginEpic";
import { getUsersEpic } from "./getUsersEpic";
import { addUserEpic } from "./addUserEpic";
import { getUserDetailEpic } from "./getUserDetailEpic";
import { getUserDetailStorageEpic } from "./getUserDetailStorageEpic";
import { getSakayaInfoEpic } from "./getSakayaInfoEpic";
import { updateSakayaInfoEpic } from "./updateSakayaInfoEpic";
import { userUpdatePasswordEpic, userResetPasswordEpic } from "./userUpdatePasswordEpic";
import { deleteUserEpic } from "./deleteUserEpic";
import { updateUserEpic } from "./updateUserEpic";

export const usersEpic = combineEpics(
    userLoginEpic,
    getUsersEpic,
    addUserEpic,
    getUserDetailEpic,
    getSakayaInfoEpic,
    getUserDetailStorageEpic,
    updateSakayaInfoEpic,
    userUpdatePasswordEpic,
    userResetPasswordEpic,
    deleteUserEpic,
    updateUserEpic
);