import {IUseCase} from '../../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../../models/response';
import CustomerRepository from '../../../../data/repository/customer';
import ValidationResult from '../../../../models/validationResult';
import { IUpdateSakayaInfoPayload } from '../../../../presentation/redux/actions/interfaces/users.types';

export default class UpdateSakayaInfoUseCase implements IUseCase {
    private customerRepository: CustomerRepository;

    /**
     *
     */
    constructor(public payload : IUpdateSakayaInfoPayload) {
        this.customerRepository = new CustomerRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.customerRepository.updateSakayaInfoRepository(this.payload).then((response: any) => {
                //console.log('updateSakayaInfoRepository:::2', response.data);
                if (response.data) {
                    // this.customerRepository.saveUserProfile(response.data.data);
                    resolve(response.data);
                } else {
                    reject(ResponseModel.createError(500, 'Sakaya Update Failed'));
                }
            }).catch(reject);
        });

    };

}
