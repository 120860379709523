import { combineEpics } from "redux-observable";
import { searchFlyersEpic } from "./searchFlyersEpic";
import { createFlyersEpic } from "./createFlyersEpic";
import { addCustomPriceEpic } from "./addCustomPriceEpic";
import { fetchMaxSeatEpic } from "./fetchMaxSeatEpic";
import { updateFlyersEpic } from "./updateFlyersEpic";
import { deleteFlyersEpic } from "./deleteFlyersEpic";
import { importFlyersEpic } from "./importFlyersEpic";
import { registerFlyerEpic } from "./registerFlyerEpic";
import { getImportFlyerHistoryEpic } from "./getImportFlyerHistoryEpic";

export const flyersEpic = combineEpics(
  searchFlyersEpic,
  createFlyersEpic,
  addCustomPriceEpic,
  fetchMaxSeatEpic,
  updateFlyersEpic,
  deleteFlyersEpic,
  importFlyersEpic,
  registerFlyerEpic,
  getImportFlyerHistoryEpic
);
