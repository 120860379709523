import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import UpdatePasswordUseCase from "../../../../domain/usecases/users/updatePassword";
import ResetPasswordUseCase from "../../../../domain/usecases/users/resetPassword";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { UserActionTypes, userUpdatePasswordSuccess, userUpdatePasswordFail, userResetPasswordSuccess, userResetPasswordFail } from "../../actions/users/user.actions";


export const userUpdatePasswordEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.USER_UPDATE_PASSWORD),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new UpdatePasswordUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('パスワードが正常に変更されました！');
                    obs.next(userUpdatePasswordSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(userUpdatePasswordFail(error.message));
                    obs.complete();
                });
            });
        })
    );


export const userResetPasswordEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(UserActionTypes.USER_RESET_PASSWORD),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ResetPasswordUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(userResetPasswordSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(userResetPasswordFail(error.message));
                    obs.complete();
                });
            });
        })
    );

