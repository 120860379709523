import { IState, IAction } from "../../../../shared/interfaces/common";
import { CustomerActionTypes } from "../../actions/customers/customer.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const getCustomerHidePriceUploadHistoryReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case CustomerActionTypes.GET_CUSTOMER_HIDE_PRICE_UPLOAD_HISTORY_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    default: {
      return state;
    }
  }
};
