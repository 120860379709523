import { IState, IAction } from "../../../../shared/interfaces/common";
import { InvoiceActionTypes } from "../../actions/invoices/invoices.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<any> = ReducerHelper.baseReducer();

export const switchInvoiceStatusReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case InvoiceActionTypes.SWITCH_INVOICE_STATUS: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case InvoiceActionTypes.SWITCH_INVOICE_STATUS_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case InvoiceActionTypes.SWITCH_INVOICE_STATUS_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    default: {
      return state;
    }
  }
};
