import CustomerRepository from "../../../../data/repository/customer";
import ResponseModel from "../../../../models/response";
import ValidationResult from "../../../../models/validationResult";
import { ICreateAddressPayload } from "../../../../presentation/redux/actions/interfaces/customers.types";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";

export default class CreateAddressUseCase implements IUseCase {
    private customerRepository: CustomerRepository;

    /**
     *
     */
    constructor(public payload: ICreateAddressPayload) {
        this.customerRepository = new CustomerRepository();
    }

    validate = (): ValidationResult => {
        return new ValidationResult(true);
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.customerRepository.createAddress(this.payload).then((response: any) => {
                    if (response.data.data) {
                        resolve(response.data);
                    } else {
                        reject(ResponseModel.createError(500, response.data.errorMessage));
                    }
                }).catch(reject);
            }
        });

    };

}
