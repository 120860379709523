import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import ApiHelper from "../../../../data/helper/ApiHelper";
import { GetPriceCustomerGroups } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { ProductActionTypes, getPriceCustomerGroupsSuccess, getPriceCustomerGroupsFail } from "../../actions/products/product.actions";


export const getPriceCustomerGroupsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.GET_PRICE_CUSTOMER_GROUPS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetPriceCustomerGroups(payload.id);
                usecase.execute().then((responses: any) => {
                    let data = ApiHelper.getPriceCustomerGroups(responses.data);
                    //console.log('getPriceCustomerGroups===999999=====', data);
                    
                    obs.next(getPriceCustomerGroupsSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getPriceCustomerGroupsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


