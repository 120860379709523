import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Windmill } from '@windmill/react-ui';
import { Provider } from "react-redux";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.scss';
import './assets/js/custom.js';
import './assets/css/tailwind.css';
import './assets/css/tailwind.output.css';
import '@pathofdev/react-tag-input/build/index.css';
import './assets/css/font-global.scss';
import './assets/css/font-global-sm.scss';
import App from './App';
import myTheme from './assets/theme/myTheme';
import { AdminProvider } from './presentation/context/AdminContext';
import { SidebarProvider } from './presentation/context/SidebarContext';
import ThemeSuspense from './presentation/components/theme/ThemeSuspense';
import store from './presentation/redux/store';
import {QueryClient, QueryClientProvider} from 'react-query';
import { createTheme, ThemeProvider } from '@mui/material';
import { breakpoints } from './shared/constants';

// import { configureStore } from "./presentation/redux/store/index";
// import {store} from './store'

// const store = configureStore();
const queryClient = new QueryClient();
const theme = createTheme({
  // match mui breakpoints with tailwind breakpoints
  breakpoints,
  typography: {
    fontFamily: '"Noto Sans JP", sans-serif'
  }
});
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AdminProvider>
          <SidebarProvider>
            <Suspense fallback={<ThemeSuspense />}>
              <Windmill usePreferences theme={myTheme}>
                <App />
              </Windmill>
            </Suspense>
          </SidebarProvider>
        </AdminProvider>
      </QueryClientProvider>
    </Provider>
 </ThemeProvider>,

  document.getElementById('root')
);
