import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { PauseOrResumeCustomerUseCase} from "../../../../domain/usecases/customer";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { CustomerActionTypes, pauseOrResumeCustomersSuccess, pauseOrResumeCustomersFail } from "../../actions/customers/customer.actions";


export const pauseOrResumeCustomerEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(CustomerActionTypes.PAUSE_OR_RESUME_CUSTOMER),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new PauseOrResumeCustomerUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(pauseOrResumeCustomersSuccess(data.data));
                    notifySuccess(payload.active ? "顧客のアカウントの再開を設定完了しました。" : "顧客のアカウントの休止を設定完了しました。");
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(pauseOrResumeCustomersFail(error.message));
                    obs.complete();
                });
            });
        })
    );


