import { IAction, IState } from "../../../../shared/interfaces/common";
import { SaleActionTypes } from "../../actions/sales/sale.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const addSaleRepresentativeReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case SaleActionTypes.ADD_SALES_REPRESENTATIVES: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case SaleActionTypes.ADD_SALES_REPRESENTATIVES_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case SaleActionTypes.ADD_SALES_REPRESENTATIVES_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    default: {
      return state;
    }
  }
};
