import { IAction, IState } from "../../../../shared/interfaces/common";
import { FlyerActionTypes } from "../../actions/flyer/flyers.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();


export const searchFlyersReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case FlyerActionTypes.SEARCH_FLYERS: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case FlyerActionTypes.SEARCH_FLYERS_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case FlyerActionTypes.SEARCH_FLYERS_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}
