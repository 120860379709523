import { IState, IAction } from "../../../../shared/interfaces/common";
import { CustomerActionTypes } from "../../actions/customers/customer.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<any> = ReducerHelper.baseReducer();

export const updateCustomerHidePriceReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case CustomerActionTypes.UPDATE_CUSTOMER_HIDE_PRICE_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    default: {
      return state;
    }
  }
};
