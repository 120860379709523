import {IUseCase} from '../../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../../models/response';
import CustomerRepository from '../../../../data/repository/customer';
import ValidationResult from '../../../../models/validationResult';
import Cookies from 'js-cookie';
import {triggerSignInFail} from "../../../../utils/plausible";

export default class LoginUserUseCase implements IUseCase {
    private customerRepository: CustomerRepository;
    private readonly email: string;
    private readonly password: string;

    /**
     *
     */
    constructor(email: string, password: string) {
        this.customerRepository = new CustomerRepository();
        this.email = email;
        this.password = password;
    }

    validate = (): ValidationResult => {
        if (this.email && this.password) {
            return new ValidationResult(true);

        }
        return new ValidationResult(false, 'Username or Password is empty');
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.customerRepository.loginUser(this.email, this.password).then((response: any) => {
                    // console.log('LOGIN FAILLLL', response);
                    
                    if (response.data.data) {

                        this.customerRepository.saveRefreshToken(response.data.data.refreshToken);
                        this.customerRepository.saveToken(response.data.data.token).then(() => {
                            resolve(response);
                        }).catch(reject);
                    } else {
                        triggerSignInFail({email: this.email}, response.data.errorMessage)
                        reject(ResponseModel.createError(500, response.data.errorMessage));
                    }
                }).catch(reject);
            }
        });

    };

}
