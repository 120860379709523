import React from "react";
import { MuiSelectOption } from "../../components/mui/form/Select";
import {
  CampaignType,
  ConditionType,
  PromotionType,
} from "../../redux/actions/interfaces/promotions";
import { DetailsNameFormData } from "./sales/CreationFormStep11";
import { DetailsTypeFormData } from "./sales/CreationFormStep12";
import { DetailsPeriodFormData } from "./sales/CreationFormStep13";
import { DetailsDescriptionFormData } from "./sales/CreationFormStep14";
import { ConditionFilledQuantityFormData } from "./sales/CreationFormStep21";
import { ConditionRewardEligibilityFormData } from "./sales/CreationFormStep22";
import { DetailsPdfFormData } from "./sales/CreationFormStep23";
import { TargetFormData } from "./sales/CreationFormStep41";
import { NotificationFormData } from "./sales/CreationFormStep51";

import { ReactComponent as DiscountIcon } from "../../../icons/Campaign_Discount.svg";
import { ReactComponent as RoyaltyIcon } from "../../../icons/Campaign_Loyalty.svg";
import { ReactComponent as PromotionIcon } from "../../../icons/Campaign_Promotion.svg";

export enum FormStep {
  DETAILS_NAME,
  DETAILS_TYPE,
  DETAILS_PERIOD,
  DETAILS_DESCRIPTION,
  CONDITIONS_FILLED_QUANTITY,
  CONDITIONS_REWARD_ELIGIBILITY,
  CONDITIONS_PDF,
  SUMMARY,
  TARGET,
  NOTIFICATION,
}

export enum StepperStep {
  DETAILS,
  CONDITIONS,
  SUMMARY,
  TARGET,
  NOTIFICATION,
}

export const formStepMapping = {
  [FormStep.DETAILS_NAME]: StepperStep.DETAILS,
  [FormStep.DETAILS_TYPE]: StepperStep.DETAILS,
  [FormStep.DETAILS_PERIOD]: StepperStep.DETAILS,
  [FormStep.DETAILS_DESCRIPTION]: StepperStep.DETAILS,
  [FormStep.CONDITIONS_FILLED_QUANTITY]: StepperStep.CONDITIONS,
  [FormStep.CONDITIONS_REWARD_ELIGIBILITY]: StepperStep.CONDITIONS,
  [FormStep.CONDITIONS_PDF]: StepperStep.CONDITIONS,
  [FormStep.SUMMARY]: StepperStep.SUMMARY,
  [FormStep.TARGET]: StepperStep.TARGET,
  [FormStep.NOTIFICATION]: StepperStep.NOTIFICATION,
};

export const formSteps: Record<StepperStep, string> = {
  [StepperStep.DETAILS]: "詳細", // Details
  [StepperStep.CONDITIONS]: "条件", // Conditions
  [StepperStep.SUMMARY]: "内容確認", // Summary
  [StepperStep.TARGET]: "対象者", // Target
  [StepperStep.NOTIFICATION]: "通知設定", // Notification
};

export interface CampaignTypeSelectionItem {
  label: string;
  value: number | string;
  icon: React.ReactNode;
  description: string;
  disabled: boolean;
}

export const campaignTypes: CampaignTypeSelectionItem[] = [
  {
    // Sample & Gifts
    label: "特典進呈",
    value: CampaignType.SampleAndGift,
    icon: <PromotionIcon width={32} height={32} />,
    // Incentivize purchases by offering free samples or gifts.
    description: "無料サンプルや特典（販促品）を進呈して購入を促進します",
    disabled: false,
  },
  {
    // Discount
    label: "割引・ディスカウント",
    value: CampaignType.Discount,
    icon: <DiscountIcon width={32} height={32} />,
    // "Boost sales with limited-time price reductions or deals.
    description: "期間限定の特価で売上を伸ばします",
    disabled: true,
  },
  {
    // Mileages & Loyalty
    label: "ポイント・会員プログラム",
    value: CampaignType.MileagesAndLoyalty,
    icon: <RoyaltyIcon width={32} height={32} />,
    // Reward purchases with points that unlock exclusive perks.
    description: "注文にポイントを付与し、限定特典を提供します",
    disabled: true,
  },
];

export const promotionTypes: MuiSelectOption[] = [
  {
    // Free sample or material
    label: "サンプル・特典（販促品）",
    value: PromotionType.FreeSampleOrMaterial,
  },
  {
    // Order discount
    label: "注文総額から割引",
    value: PromotionType.OrderDiscount,
    disabled: true,
  },
  {
    // Product discount
    label: "商品単価から割引",
    value: PromotionType.ProductDiscount,
    disabled: true,
  },
  {
    // Free shipping
    label: "配送無料",
    value: PromotionType.FreeShipping,
    disabled: true,
  },
];

export const conditionTypes: MuiSelectOption[] = [
  {
    // Order includes product
    label: "指定の商品が注文される",
    value: ConditionType.OrderIncludeProduct,
  },
  {
    // Order includes product brand / maker
    label: "指定のメーカー・ブランドの商品が注文される",
    value: ConditionType.OrderIncludeBrandManufacturer,
    disabled: true,
  },
  {
    // Order include product tag
    label: "指定の商品タグの商品が注文される",
    value: ConditionType.OrderIncludeProductTag,
    disabled: true,
  },
  {
    // Cart total
    label: "注文合計金額",
    value: ConditionType.CartTotal,
    disabled: true,
  },
];

export enum StartDateType {
  IMMEDIATELY = "immediately",
  SET = "set",
}

export enum EndDateType {
  NEVER_ENDING = "never_ending",
  SET = "set",
}

export enum RelatedCondition {
  AND = "and",
  OR = "or",
}

export enum EligibilityType {
  TIMES = 1,
  UNLIMITED_OVER_CAMPAIGN_PERIOD = 2,
}

export enum NotificationScheduleType {
  SEND_NOW = "send_now",
  SCHEDULED = "scheduled",
}

export enum TargetSegmentType {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}

export type PromotionCampaignFormData = Partial<
  // Details
  DetailsNameFormData &
    DetailsTypeFormData &
    DetailsPeriodFormData &
    DetailsDescriptionFormData &
    // Conditions
    ConditionFilledQuantityFormData &
    ConditionRewardEligibilityFormData &
    DetailsPdfFormData &
    // Target
    TargetFormData &
    NotificationFormData & {
      // use for update
      id: string;
      sakayaId: string;
    }
>;

export interface SalesPromotionFormProviderType {
  formStep: FormStep;
  setFormStep: React.Dispatch<React.SetStateAction<FormStep>>;
  formData: PromotionCampaignFormData;
  setFormData: (data: PromotionCampaignFormData) => void;
  resetFormData: () => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToSummary: () => void;
  isSummary: boolean;
  setIsSummary: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SalesPromotionFormContext =
  React.createContext<SalesPromotionFormProviderType | null>(null);

export default function SalesPromotionFormProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [formStep, setFormStep] = React.useState(FormStep.DETAILS_NAME);

  const [isSummary, setIsSummary] = React.useState(false);

  const [formData, _setFormData] = React.useState<PromotionCampaignFormData>(
    {}
  );

  const setFormData = (data: PromotionCampaignFormData) => {
    _setFormData((prev) => ({ ...prev, ...data }));
  };

  const resetFormData = () => {
    _setFormData({});
  };

  const goToSummary = () => {
    setFormStep(FormStep.SUMMARY);
  };

  const goToNextStep = () => {
    if (isSummary && formStep < FormStep.SUMMARY) {
      goToSummary();
    } else {
      setFormStep(formStep + 1);
    }
  };

  const goToPrevStep = () => {
    if (formStep >= 1) {
      setFormStep(formStep - 1);
    }
  };

  return (
    <SalesPromotionFormContext.Provider
      value={{
        formStep,
        setFormStep,
        formData,
        setFormData,
        resetFormData,
        goToNextStep,
        goToPrevStep,
        goToSummary,
        isSummary,
        setIsSummary,
      }}
    >
      {children}
    </SalesPromotionFormContext.Provider>
  );
}
