import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import ProductRepository from '../../../data/repository/product';
import { IUpdateCustomerGroupPricePayload } from '../../../presentation/redux/actions/interfaces/product.types';

export default class UpdateCustomerGroupPriceUseCase implements IUseCase {
    private productRepository: ProductRepository;

    /**
     *
     */
    constructor(public payload: IUpdateCustomerGroupPricePayload) {
        this.productRepository = new ProductRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.productRepository.updateCustomerGroupPriceRepository(this.payload).then((response: any) => {
                //console.log('updateCustomerGroupPriceRepository:::2', response.data);
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(ResponseModel.createError(500, 'Price Group Update Failed'));
                }
            }).catch(reject);
        });

    };

}
