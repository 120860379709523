import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import ApiHelper from "../../../../data/helper/ApiHelper";
import { CreateCustomerGroupPrice } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, getPriceCustomerGroupsSuccess, createCustomerGroupPriceSuccess, createCustomerGroupPriceFail } from "../../actions/products/product.actions";


export const createCustomerGroupPriceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.CREATE_CUSTOMER_GROUP_PRICE),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CreateCustomerGroupPrice(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    const customerGroupPriceData = [] as any[];
                    state$.value.productsReducer.getPriceCustomerGroupsReducer.data.forEach((element: { company: any; customerGroupId: any; customerName: any; defaultPrice: any; id: any; name: any; parentCustomerGroupId: any; pricePerItem: any; salePrice: any; }) => {
                        customerGroupPriceData.push({
                            company: element.company,
                            customerGroupId: element.customerGroupId,
                            customerName: element.customerName,
                            defaultPrice: element.defaultPrice,
                            id: element.id,
                            name: element.name,
                            parentCustomerGroupId: element.parentCustomerGroupId,
                            pricePerItem: element.pricePerItem,
                            salePrice: element.salePrice
                        })
                    });
                    //const customerGroupPriceData = state$.value.productsReducer.getPriceCustomerGroupsReducer.data;
                    const newGroupPrice = {
                        ...payload,
                        id: data,
                    }

                    customerGroupPriceData.push(newGroupPrice);

                    obs.next(getPriceCustomerGroupsSuccess(customerGroupPriceData));

                    notifySuccess('特別価格を作成しました！');
                    obs.next(createCustomerGroupPriceSuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(createCustomerGroupPriceFail(error.message));
                    obs.complete();
                });
            });
        })
    );
