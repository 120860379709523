import { TokenType } from "../../../../data/constants";
import { IAction, IState } from "../../../../shared/interfaces/common";
import { UserActionTypes } from "../../actions/users/user.actions";
import ReducerHelper from "../../rootReducers/helper";

const data = {
    // access_token: JSON.parse(localStorage.getItem(TokenType.AccessToken)|| ''),
}

const initialState: IState<string> = ReducerHelper.baseReducer();


export const loginReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case UserActionTypes.USER_LOGIN: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case UserActionTypes.USER_LOGIN_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case UserActionTypes.USER_LOGIN_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}
