import { IAction } from "../../../../shared/interfaces/common";
import { ICompleteUploadPayload, ICreateBulkInvoiceJobPayload, IGetInvoiceListPayload, IGetInvoicePayload, IGetBulkUploadHistoriesPayload, IGetSignedUrlPayload, IPublishInvoicesPayload, ISearchInvoicesPayload, ISingleInvoicePayload, ISwitchInvoiceStatusPayload, IDownloadInvoiceBillPayload } from "../interfaces/invoices.types";

// Action Types
export enum InvoiceActionTypes {
  SEARCH_INVOICES = "[Invoices] SEARCH_INVOICES",
  SEARCH_INVOICES_SUCCESS = "[Invoices] SEARCH_INVOICES_SUCCESS",
  SEARCH_INVOICES_FAIL = "[Invoices] SEARCH_INVOICES_FAIL",

  CREATE_INVOICE = "[Invoices] CREATE_INVOICE",
  CREATE_INVOICE_SUCCESS = "[Invoices] CREATE_INVOICE_SUCCESS",
  CREATE_INVOICE_FAIL = "[Invoices] CREATE_INVOICE_FAIL",

  CREATE_INVOICE_VERSION = "[Invoices] CREATE_INVOICE_VERSION",
  CREATE_INVOICE_VERSION_SUCCESS = "[Invoices] CREATE_INVOICE_VERSION_SUCCESS",
  CREATE_INVOICE_VERSION_FAIL = "[Invoices] CREATE_INVOICE_VERSION_FAIL",

  DELETE_INVOICE = "[Invoices] DELETE_INVOICE",
  DELETE_INVOICE_SUCCESS = "[Invoices] DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAIL = "[Invoices] DELETE_INVOICE_FAIL",

  RESTORE_INVOICE = "[Invoices] RESTORE_INVOICE",
  RESTORE_INVOICE_SUCCESS = "[Invoices] RESTORE_INVOICE_SUCCESS",
  RESTORE_INVOICE_FAIL = "[Invoices] RESTORE_INVOICE_FAIL",

  DOWNLOAD_INVOICE = "[Invoices] DOWNLOAD_INVOICE",
  DOWNLOAD_INVOICE_SUCCESS = "[Invoices] DOWNLOAD_INVOICE_SUCCESS",
  DOWNLOAD_INVOICE_FAIL = "[Invoices] DOWNLOAD_INVOICE_FAIL",

  SEND_INVOICE_REMINDER = "[Invoices] SEND_INVOICE_REMINDER",
  SEND_INVOICE_REMINDER_SUCCESS = "[Invoices] SEND_INVOICE_REMINDER_SUCCESS",
  SEND_INVOICE_REMINDER_FAIL = "[Invoices] SEND_INVOICE_REMINDER_FAIL",

  CREATE_BULK_UPLOAD_JOB = "[Invoices] CREATE_BULK_UPLOAD_JOB",
  CREATE_BULK_UPLOAD_JOB_SUCCESS = "[Invoices] CREATE_BULK_UPLOAD_JOB_SUCCESS",
  CREATE_BULK_UPLOAD_JOB_FAIL = "[Invoices] CREATE_BULK_UPLOAD_JOB_FAIL",

  SEARCH_DELETED_INVOICES = "[Invoices] SEARCH_DELETED_INVOICES",
  SEARCH_DELETED_INVOICES_SUCCESS = "[Invoices] SEARCH_DELETED_INVOICES_SUCCESS",
  SEARCH_DELETED_INVOICES_FAIL = "[Invoices] SEARCH_DELETED_INVOICES_FAIL",

  SEARCH_DRAFT_INVOICES = "[Invoices] SEARCH_DRAFT_INVOICES",
  SEARCH_DRAFT_INVOICES_SUCCESS = "[Invoices] SEARCH_DRAFT_INVOICES_SUCCESS",
  SEARCH_DRAFT_INVOICES_FAIL = "[Invoices] SEARCH_DRAFT_INVOICES_FAIL",

  HAS_PENDING_JOB = "[Invoices] HAS_PENDING_JOB",
  HAS_PENDING_JOB_SUCCESS = "[Invoices] HAS_PENDING_JOB_SUCCESS",
  HAS_PENDING_JOB_FAIL = "[Invoices] HAS_PENDING_JOB_FAIL",

  PUBLISH_INVOICES = "[Invoices] PUBLISH_INVOICES",
  PUBLISH_INVOICES_SUCCESS = "[Invoices] PUBLISH_INVOICES_SUCCESS",
  PUBLISH_INVOICES_FAIL = "[Invoices] PUBLISH_INVOICES_FAIL",

  GET_BULK_UPLOAD_HISTORIES = "[Invoices] GET_BULK_UPLOAD_HISTORIES",
  GET_BULK_UPLOAD_HISTORIES_SUCCESS = "[Invoices] GET_BULK_UPLOAD_HISTORIES_SUCCESS",
  GET_BULK_UPLOAD_HISTORIES_FAIL = "[Invoices] GET_BULK_UPLOAD_HISTORIES_FAIL",

  SWITCH_INVOICE_STATUS = "[Notifications] SWITCH_INVOICE_STATUS",
  SWITCH_INVOICE_STATUS_SUCCESS = "[Notifications] SWITCH_INVOICE_STATUS_SUCCESS",
  SWITCH_INVOICE_STATUS_FAIL = "[Notifications] SWITCH_INVOICE_STATUS_FAIL",

  GET_SIGNED_URL = "[Notifications] GET_SIGNED_URL",
  GET_SIGNED_URL_SUCCESS = "[Notifications] GET_SIGNED_URL_SUCCESS",
  GET_SIGNED_URL_FAIL = "[Notifications] GET_SIGNED_URL_FAIL",

  COMPLETE_UPLOAD = "[Notifications] COMPLETE_UPLOAD",
  COMPLETE_UPLOAD_SUCCESS = "[Notifications] COMPLETE_UPLOAD_SUCCESS",
  COMPLETE_UPLOAD_FAIL = "[Notifications] COMPLETE_UPLOAD_FAIL",

  GET_INVOICE_DETAIL = "[Invoices] GET_INVOICE_DETAIL",
  GET_INVOICE_DETAIL_SUCCESS = "[Invoices] GET_INVOICE_DETAIL_SUCCESS",
  GET_INVOICE_DETAIL_FAIL = "[Invoices] GET_INVOICE_DETAIL_FAIL",

  GET_INVOICE_LIST = "[Invoices] GET_INVOICE_LIST",
  GET_INVOICE_LIST_SUCCESS = "[Invoices] GET_INVOICE_LIST_SUCCESS",
  GET_INVOICE_LIST_FAIL = "[Invoices] GET_INVOICE_LIST_FAIL",

  DOWNLOAD_INVOICE_BILL = "[Invoices] DOWNLOAD_INVOICE_BILL",
  DOWNLOAD_INVOICE_BILL_SUCCESS = "[Invoices] DOWNLOAD_INVOICE_BILL_SUCCESS",
  DOWNLOAD_INVOICE_BILL_FAIL = "[Invoices] DOWNLOAD_INVOICE_BILL_FAIL",

  FILTER_INVOICE_BILL = "[Invoices] FILTER_INVOICE_BILL",
}

export const searchInvoices = (
  payload?: ISearchInvoicesPayload
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_INVOICES,
  payload,
});

export const searchInvoicesSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_INVOICES_SUCCESS,
  payload,
});

export const searchInvoicesFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_INVOICES_FAIL,
  error,
});

export const createInvoice = (
  payload: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_INVOICE,
  payload,
});

export const createInvoiceSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_INVOICE_SUCCESS,
  payload,
});

export const createInvoiceFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_INVOICE_FAIL,
  error,
});

export const createInvoiceVersion = (
  payload: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_INVOICE_VERSION,
  payload,
});

export const createInvoiceVersionSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_INVOICE_VERSION_SUCCESS,
  payload,
});

export const createInvoiceVersionFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_INVOICE_VERSION_FAIL,
  error,
});

export const deleteInvoice = (
  payload: ISingleInvoicePayload
): IAction<any> => ({
  type: InvoiceActionTypes.DELETE_INVOICE,
  payload,
});

export const deleteInvoiceSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.DELETE_INVOICE_SUCCESS,
  payload,
});

export const deleteInvoiceFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.DELETE_INVOICE_FAIL,
  error,
});

export const restoreInvoice = (
  payload: ISingleInvoicePayload
): IAction<any> => ({
  type: InvoiceActionTypes.RESTORE_INVOICE,
  payload,
});

export const restoreInvoiceSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.RESTORE_INVOICE_SUCCESS,
  payload,
});

export const restoreInvoiceFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.RESTORE_INVOICE_FAIL,
  error,
});

export const downloadInvoice = (
  payload: ISingleInvoicePayload
): IAction<any> => ({
  type: InvoiceActionTypes.DOWNLOAD_INVOICE,
  payload,
});

export const downloadInvoiceSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.DOWNLOAD_INVOICE_SUCCESS,
  payload,
});

export const downloadInvoiceFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.DOWNLOAD_INVOICE_FAIL,
  error,
});

export const sendInvoiceReminder = (
  payload: ISingleInvoicePayload
): IAction<any> => ({
  type: InvoiceActionTypes.SEND_INVOICE_REMINDER,
  payload,
});

export const sendInvoiceReminderSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEND_INVOICE_REMINDER_SUCCESS,
  payload,
});

export const sendInvoiceReminderFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEND_INVOICE_REMINDER_FAIL,
  error,
});

export const createBulkUploadJob = (
  payload: ICreateBulkInvoiceJobPayload
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_BULK_UPLOAD_JOB,
  payload,
});

export const createBulkUploadJobSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_BULK_UPLOAD_JOB_SUCCESS,
  payload,
});

export const createBulkUploadJobFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.CREATE_BULK_UPLOAD_JOB_FAIL,
  error,
});

export const searchDeletedInvoices = (
  payload?: ISearchInvoicesPayload
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_DELETED_INVOICES,
  payload,
});

export const searchDeletedInvoicesSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_DELETED_INVOICES_SUCCESS,
  payload,
});

export const searchDeletedInvoicesFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_DELETED_INVOICES_FAIL,
  error,
});

export const searchDraftInvoices = (
  payload?: ISearchInvoicesPayload
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_DRAFT_INVOICES,
  payload,
});

export const searchDraftInvoicesSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_DRAFT_INVOICES_SUCCESS,
  payload,
});

export const searchDraftInvoicesFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.SEARCH_DRAFT_INVOICES_FAIL,
  error,
});

export const hasPendingJob = (
): IAction<any> => ({
  type: InvoiceActionTypes.HAS_PENDING_JOB
});

export const hasPendingJobSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.HAS_PENDING_JOB_SUCCESS,
  payload,
});

export const hasPendingJobFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.HAS_PENDING_JOB_FAIL,
  error,
});

export const publishInvoices = (
  payload?: IPublishInvoicesPayload
): IAction<any> => ({
  type: InvoiceActionTypes.PUBLISH_INVOICES,
  payload,
});

export const publishInvoicesSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.PUBLISH_INVOICES_SUCCESS,
  payload,
});

export const publishInvoicesFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.PUBLISH_INVOICES_FAIL,
  error,
});

export const getBulkUploadHistories = (
  payload?: IGetBulkUploadHistoriesPayload
): IAction<any> => ({
  type: InvoiceActionTypes.GET_BULK_UPLOAD_HISTORIES,
  payload,
});

export const getBulkUploadHistoriesSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_BULK_UPLOAD_HISTORIES_SUCCESS,
  payload,
});

export const getBulkUploadHistoriesFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_BULK_UPLOAD_HISTORIES_FAIL,
  error,
});

export const switchInvoiceStatus = (
  payload?: ISwitchInvoiceStatusPayload
): IAction<any> => ({
  type: InvoiceActionTypes.SWITCH_INVOICE_STATUS,
  payload,
});

export const switchInvoiceStatusSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.SWITCH_INVOICE_STATUS_SUCCESS,
  payload,
});

export const switchInvoiceStatusFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.SWITCH_INVOICE_STATUS_FAIL,
  error,
});

export const getSignedUrl = (
  payload?: IGetSignedUrlPayload
): IAction<any> => ({
  type: InvoiceActionTypes.GET_SIGNED_URL,
  payload,
});

export const getSignedUrlSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_SIGNED_URL_SUCCESS,
  payload,
});

export const getSignedUrlFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_SIGNED_URL_FAIL,
  error,
});

export const completeUpload = (
  payload?: ICompleteUploadPayload
): IAction<any> => ({
  type: InvoiceActionTypes.COMPLETE_UPLOAD,
  payload,
});

export const completeUploadSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.COMPLETE_UPLOAD_SUCCESS,
  payload,
});

export const completeUploadFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.COMPLETE_UPLOAD_FAIL,
  error,
});

export const getInvoiceDetail = (
  payload?: IGetInvoicePayload
): IAction<any> => ({
  type: InvoiceActionTypes.GET_INVOICE_DETAIL,
  payload,
});

export const getInvoiceDetailSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_INVOICE_DETAIL_SUCCESS,
  payload,
});

export const getInvoiceDetailFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_INVOICE_DETAIL_FAIL,
  error,
});

export const getInvoiceList = (
  payload: IGetInvoiceListPayload
): IAction<any> => ({
  type: InvoiceActionTypes.GET_INVOICE_LIST,
  payload,
});

export const getInvoiceListSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_INVOICE_LIST_SUCCESS,
  payload,
});

export const getInvoiceListFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.GET_INVOICE_LIST_FAIL,
  error,
});

export const downloadInvoiceBill = (
  payload: IDownloadInvoiceBillPayload
): IAction<any> => ({
  type: InvoiceActionTypes.DOWNLOAD_INVOICE_BILL,
  payload,
});

export const downloadInvoiceBillSuccess = (
  payload?: any
): IAction<any> => ({
  type: InvoiceActionTypes.DOWNLOAD_INVOICE_BILL_SUCCESS,
  payload,
});

export const downloadInvoiceBillFail = (
  error: any
): IAction<any> => ({
  type: InvoiceActionTypes.DOWNLOAD_INVOICE_BILL_FAIL,
  error,
});

export const filterInvoiceBill = (
  payload: any
): IAction<any> => ({
  type: InvoiceActionTypes.FILTER_INVOICE_BILL,
  payload,
});