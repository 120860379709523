import { IAction, IState } from "../../../../shared/interfaces/common";
import { OrderActionTypes } from "../../actions/orders/order.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const deleteSelectedCollectionReducer = (state = initialState, action: IAction<any>) => {
    switch (action.type) {
        case OrderActionTypes.DELETE_SELECTED_OPTIONS: {
            return ReducerHelper.baseProcessRequest(state, action);
        }
        case OrderActionTypes.DELETE_SELECTED_OPTIONS_SUCCESS: {
            return ReducerHelper.baseProcessSuccess(state, action);
        }
        case OrderActionTypes.DELETE_SELECTED_OPTIONS_FAIL: {
            return ReducerHelper.baseProcessFailed(state, action);
        }
     
        default: {
            return state;
        }
    }
}
