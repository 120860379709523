import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import {  ExportQRCodesUseCase } from "../../../../domain/usecases/customer";
import { IAction } from "../../../../shared/interfaces/common";
import { exportQRCodesSuccess, CustomerActionTypes, exportQRCodesFail } from "../../actions/customers/customer.actions";

export const exportQRCodesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(CustomerActionTypes.EXPORT_QR_CODES),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ExportQRCodesUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(exportQRCodesSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(exportQRCodesFail(error.message));
                    obs.complete();
                });
            });
        })
    );


