import { IUseCase } from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import ProductRepository from '../../../data/repository/product';
import ValidationResult from '../../../models/validationResult';

export default class GetDetailErrorUseCase implements IUseCase {
  private productRepository: ProductRepository;

  constructor(public payload: any) {
    this.productRepository = new ProductRepository();
  }

  validate = (): ValidationResult => {
    return new ValidationResult(true);
  };

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      const validationResult = this.validate();

      if (validationResult.isValid) {
        this.productRepository.getDetailErrorRepository(this.payload)
          .then((response: any) => {
            if (response.data.data) {
              resolve(response.data);
            } else {
              reject(
                ResponseModel.createError(500, response.data.errorMessage)
              );
            }
          })
          .catch(reject);
      }
    });
  };
}
