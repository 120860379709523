import {IUseCase} from '../../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../../models/response';
import CustomerRepository from '../../../../data/repository/customer';
import ValidationResult from '../../../../models/validationResult';

export default class GetSakayaInfoUseCase implements IUseCase {
    private customerRepository: CustomerRepository;

    /**
     *
     */
    constructor() {
        this.customerRepository = new CustomerRepository();
    }


    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            this.customerRepository.getSakayaInfoRepository().then((response: any) => {
                //console.log('getSakayaInfoRepository:::2', response.data);
                if (response.data.data) {
                    // this.customerRepository.saveUserProfile(response.data.data);
                    resolve(response.data);
                } else {
                    reject(ResponseModel.createError(500, 'User is null'));
                }
            }).catch(reject);
        });

    };

}
