import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { NotificationActionTypes, settingNotificationsSuccess, settingNotificationsFail } from "../../actions/notification/notification.actions";
import { SettingNotificationsUseCase } from "../../../../domain/usecases/notification";
import { notifyError, notifySuccess } from "../../../../utils/toast";

export const settingNotificationsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(NotificationActionTypes.SETTING_NOTIFICATIONS),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SettingNotificationsUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('設定が保存されました。');
                    obs.next(settingNotificationsSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    notifyError("設定を保存できませんでした。再度お試しください。");
                    obs.next(settingNotificationsFail(error.message));
                    obs.complete();
                });
            });
        })
    );


