import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, createBulkUploadJobFail, createBulkUploadJobSuccess, searchInvoicesFail, searchInvoicesSuccess, sendInvoiceReminderFail, sendInvoiceReminderSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import { CreateBulkUploadJobUseCase, SendInvoiceReminderUseCase } from "../../../../domain/usecases/invoices";


export const createBulkUploadJobEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.CREATE_BULK_UPLOAD_JOB),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CreateBulkUploadJobUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(createBulkUploadJobSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(createBulkUploadJobFail(error.message));
                    obs.complete();
                });
            });
        })
    );


