import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { FlyerActionTypes, getImportFlyerHistoryFail, getImportFlyerHistorySuccess } from "../../actions/flyer/flyers.actions";
import { GetImportFlyerHistoryUserCase } from "../../../../domain/usecases/flyer";

export const getImportFlyerHistoryEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(FlyerActionTypes.GET_IMPORT_FLYER_HISTORY),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetImportFlyerHistoryUserCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getImportFlyerHistorySuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getImportFlyerHistoryFail(error.message));
                    obs.complete();
                });
            });
        })
    );


