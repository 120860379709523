import { combineReducers } from "@reduxjs/toolkit";
import { getOrdersReducer } from "./getOrdersReducer";
import { getOrderReducer } from "./getOrderReducer";
import { changeOrderStatusReducer } from "./changeOrderStatusReducer";
import { changeOrderStatusProductReducer } from "./changeOrderStatusProductReducer";
import { getOrdersByCustomerReducer } from "./getOrdersByCustomerReducer";
import { exportOrdersReducer } from "./exportOrdersReducer";
import { exportSalesReducer } from "./exportSalesReducer";
import { updateOrderReducer } from "./updateOrderReducer";
import { updateOrderProductsReducer } from "./updateOrderProductsReducer";
import { getRevenueInformationReducer } from "./getRevenueInformationReducer";
import { exportPurchaseOrdersReducer } from "./exportPurchaseOrdersReducer";
import { exportSakayasInfoSummaryReducer } from "./exportSakayasInfoSummaryReducer";
import { resendOrderConfirmationReducer } from "./resendOrderConfirmationReducer";
import { getPurchasedProductsReducer } from "./getPurchasedProductsReducer";
import { sendProductRejectedNotificationReducer } from "./sendProductRejectedNotificationReducer";
import { saveFirebaseTokenReducer } from "./saveFirebaseTokenReducer";
import { getOrderSummaryReducer } from "./getOrderSummaryReducer";
import { getSakayasInfoSummaryReducer } from "./getSakayasInfoSummaryReducer";
import { generateProductsPdfReducer } from "./generateProductsPdfReducer";
import { createSelectedCollectionReducer } from "./createSelectedCollectionReducer";
import { deleteSelectedCollectionReducer } from "./deleteSelectedCollectionReducer";
import { getProductsSelectedReducer } from "./getProductsSelectedReducer";
import { getFaxFilesReducer } from "./getFaxFilesReducer";
import { getFaxContentReducer } from "./getFaxContentReducer";
import { cancelFaxReducer } from "./cancelFaxReducer";
import { scanFaxImagesReducer } from "./scanFaxImagesReducer";
import { createOrderReducer } from "./createOrderReducer";
import { exportOrdersPosReducer } from "./exportOrdersPosReducer";
import { exportOrdersHistoriesReducer } from "./exportOrdersHistoriesReducer";
import { exportOrdersV2Reducer } from "./exportOrdersV2Reducer";
import { setHidePriceTotal } from "./setIsHidePriceTotal";

export const ordersReducer = combineReducers({
    getOrderReducer,
    getOrdersReducer,
    changeOrderStatusReducer,
    changeOrderStatusProductReducer,
    getOrdersByCustomerReducer,
    exportOrdersReducer,
    exportSalesReducer,
    updateOrderReducer,
    updateOrderProductsReducer,
    getRevenueInformationReducer,
    exportPurchaseOrdersReducer,
    exportSakayasInfoSummaryReducer,
    resendOrderConfirmationReducer,
    getPurchasedProductsReducer,
    sendProductRejectedNotificationReducer,
    saveFirebaseTokenReducer,
    getOrderSummaryReducer,
    getSakayasInfoSummaryReducer,
    generateProductsPdfReducer,
    createSelectedCollectionReducer,
    deleteSelectedCollectionReducer,
    getProductsSelectedReducer,
    scanFaxImagesReducer,
    createOrderReducer,
    getFaxFilesReducer,
    getFaxContentReducer,
    cancelFaxReducer,
    exportOrdersPosReducer,
    exportOrdersHistoriesReducer,
    exportOrdersV2Reducer,
    setHidePriceTotal
});