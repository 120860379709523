import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { GetVariantDetailUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { ProductActionTypes, getVariantDetailSuccess, getVariantDetailFail } from "../../actions/products/product.actions";


export const getVariantDetailEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.GET_VARIANT_DETAIL),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetVariantDetailUseCase(payload.id);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    obs.next(getVariantDetailSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getVariantDetailFail(error.message));
                    obs.complete();
                });
            });
        })
    );


