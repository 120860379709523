import { IUseCase } from '../../../../shared/interfaces/common/usecase';
import ValidationResult from '../../../../models/validationResult';
import ResponseModel from '../../../../models/response';
import CustomerRepository from '../../../../data/repository/customer';
import Utilities from '../../../../shared/helper/utilities';
import { SakayaData } from '../../../../data/gateway/graphql/queries/orders/getSakayas';

export default class GetNotAssignSakayasUseCase implements IUseCase {
    private customerRepository: CustomerRepository;

    constructor() {
        this.customerRepository = new CustomerRepository();
    }

    validate = (): ValidationResult => {
            return new ValidationResult(true);
    };

    execute = (): Promise<SakayaData> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.customerRepository.getUserProfile().then((response: any) => {
                    if (response.data) {
                        this.customerRepository.getSakayas()
                            .then((response: any) => {
                                const data = response.data.data;
                                if (data) {
                                    resolve(data);
                                } else {
                                    reject(ResponseModel.createError(500, response.errors[0].message));
                                }
                            })
                            .catch((error: any) => {
                                reject(ResponseModel.createError(500, JSON.stringify(error)));
                            });
                    } else {
                        reject(ResponseModel.createError(500, 'User is null'));
                    }
                }).catch(reject);

                
            }
        });
    };
}
