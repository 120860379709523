import CustomerRepository from "../../../../data/repository/customer";
import ResponseModel from "../../../../models/response";
import { IUseCase } from "../../../../shared/interfaces/common/usecase";

export default class updateCustomerAddressUseCase implements IUseCase {
  private customerRepository: CustomerRepository;
  private readonly _payload: any;

  constructor(payload: any) {
    this.customerRepository = new CustomerRepository();
    this._payload = payload;
  }

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      this.customerRepository
        .updateCustomerAddress(this._payload)
        .then((response: any) => {
          if (response.data.success) {
            resolve(response.data);
          } else {
            reject(ResponseModel.createError(500, response.data.errorMessage));
          }
        })
        .catch(reject);
    });
  };
}
