import {IUseCase} from '../../../shared/interfaces/common/usecase';
import ResponseModel from '../../../models/response';
import OrderRepository from '../../../data/repository/order';
import ValidationResult from '../../../models/validationResult';
import { notifyError, notifySuccess } from '../../../utils/toast';

export default class CancelFaxUseCase implements IUseCase {
    private orderRepository: OrderRepository;

    /**
     *
     */
    constructor(public payload: string) {
        this.orderRepository = new OrderRepository();
    }

    validate = (): ValidationResult => {
        return new ValidationResult(true);
    };

    execute = (): Promise<ResponseModel<string>> => {
        return new Promise((resolve, reject) => {
            const validationResult = this.validate();
            if (validationResult.isValid) {
                this.orderRepository.cancelFax(this.payload).then((response: any) => {
                    if (response.data.data) {
                        notifySuccess('FAXステータスの更新完了!');
                        resolve(response.data);
                    } else {
                        notifyError(`FAXステータスの更新エラー ${response.data.errorMessage}!`);
                        reject(ResponseModel.createError(500, response.data.errorMessage));
                    }
                }).catch(reject);
            }
        });

    };

}
