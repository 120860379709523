export const User = {
    Key: 'KeyUser',
    Auth: 'AuthToken',
    RememberLogin: 'RememberLoginKey'
};

export const TokenType = {
    Customer: 'access_token',
    Admin: 'admin',
    Integration: 'Token.Integration',
    DeviceToken: 'Token.DeviceToken',
    Locale: 'Locale',
    AccessToken: 'access_token',
    ResetToken: 'reset_token',
    RefreshToken: 'refresh_token',
    UserInfo: 'user_info',
    ProductDetail: 'product_detail',
};

export const General = {
    StoreConfigs: '@StoreConfigs',
    Countries: '@Countries',
    CurrencyConfig: '@CurrencyConfig'
};

export const buttonBgColor = {  
    success: '#198754',
    primary: '#0d6efd',
    secondary: '#6c757d',
    danger: '#dc3545',
    info: '#0dcaf0',
    warning: '#ffc107',
    black: '#212529',
    light: '#f8f9fa',
    white: '#fff',
    red: '#f70404'

}

export const textColor = {
    success: '#198754',
    primary: '#0d6efd',
    secondary: '#6c757d',
    danger: '#dc3545',
    info: '#0dcaf0',
    warning: '#ffc107',
    black: '#212529',
    light: '#f8f9fa',
    white: '#fff',
    red: '#f70404'
}

export const maxFileSize = {
    customPrice: 1024 * 1024 * 3,
}
export const UserBehaviorOptions = [
    {
      id: 1,
      active: true,
      name: 'Has ordered',
      criteriaOptions: [
        { id: 1, name: 'Product' },
        { id: 2, name: 'Category' },
      ],
      averageOptions: [
        { id: 1, name: 'More Than' },
        { id: 2, name: 'Less Than' },
        // { id: 3, name: 'Equal To' },
      ],
      unitTimeOptions: [
        { id: 1, name: 'Hours' },
        { id: 2, name: 'Days' },
        { id: 3, name: 'Weeks' },
        { id: 4, name: 'Months' },
        // { id: 5, name: 'Times' }
      ]
    },
    {
      id: 2, 
      active: true,
      name: "Hasn't ordered",
      criteriaOptions: [
        { id: 1, name: 'Product' },
        { id: 2, name: 'Category' },
      ],
      averageOptions: [
        { id: 1, name: 'More Than' },
        { id: 2, name: 'Less Than' },
        // { id: 3, name: 'Equal To' },
      ],
      unitTimeOptions: [
        { id: 1, name: 'Hours' },
        { id: 2, name: 'Days' },
        { id: 3, name: 'Weeks' },
        { id: 4, name: 'Months' },
        // { id: 5, name: 'Times' }
      ]
    },
    {
      id: 3, 
      active: false,
      name: 'Has clicked',
      criteriaOptions: [
        { id: 3, name: 'Banner' }
      ],
      averageOptions: [
        { id: 1, name: 'in' }
      ],
      unitTimeOptions: [
        { id: 1, name: 'Times' }
      ]
    },
    {
      id: 4, 
      active: false,
      name: "Hasn't clicked",
      criteriaOptions: [
        { id: 3, name: 'Banner' }
      ],
      averageOptions: [
        { id: 1, name: 'in' }
      ],
      unitTimeOptions: [
        { id: 1, name: 'Times' }
      ]
    }
  ]; 
export const NotificationTypes = [
  { id: -1, name: '全て' },
  { id: 1, name: '一般通知' },
  { id: 2, name: 'システム関連のお知らせ' },
  { id: 3, name: 'プロモーション' },
  { id: 4, name: '価格改定' },
  { id: 5, name: '配達遅延のお知らせ' },
  { id: 6, name: '本日の配達予定' },
  { id: 7, name: '欠品のお知らせ' },
  // { id: 8, name: '請求書のお知らせ' },
  { id: 9, name: '終売・代替品のお知らせ' },
  { id: 10, name: '営業担当の不在' }
];  

export const enum ConstantHidePricesOptions {
    NotSet = "NotSet",
    AllPrices = "AllPrices",
    CustomPricesOnly = "CustomPricesOnly",
    DefaultPricesOnly = "DefaultPricesOnly"
}

export const HidePriceOptions = [
    {
      id: 1,
      label: "価格表示",
      value: ConstantHidePricesOptions.NotSet,
    },
    {
      id: 2,
      label: "価格非表示",
      value: ConstantHidePricesOptions.AllPrices,
    },
    {
      id: 3,
      label: "Custom prices only",
      value: ConstantHidePricesOptions.CustomPricesOnly,
    },
    {
      id: 4,
      label: "Default prices only",
      value: ConstantHidePricesOptions.DefaultPricesOnly,
    }
  ];
  export const renderLabelHidePrice = (value: string) => {
    let render = "";

    switch (value) {
      case ConstantHidePricesOptions.CustomPricesOnly:
        render = "Custom";
        break;
      case ConstantHidePricesOptions.AllPrices:
        render = "価格非表示";
        break;
      case ConstantHidePricesOptions.DefaultPricesOnly:
        render = 'Default';
        break
      default:
        render = "価格表示";
        break;
    }

    return render;
  };
