import { ofType } from "redux-observable";
import {
  CustomerActionTypes,
  importHidePriceFail,
  importHidePriceSuccess,
} from "../../actions/customers/customer.actions";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { Observable } from "rxjs";
import ImportHidePriceUseCase from "../../../../domain/usecases/customer/importHidePrice";

export const importHidePriceEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(CustomerActionTypes.IMPORT_HIDE_PRICE),

    exhaustMap((action: IAction<any>) => {
      return new Observable((obs) => {
        const { payload } = action;

        const useCase = new ImportHidePriceUseCase(payload);

        useCase
          .execute()
          .then((responses: any) => {
            const data = responses;

            obs.next(importHidePriceSuccess(data.data));

            obs.complete();
          })
          .catch((error) => {
            obs.next(importHidePriceFail(error.message));

            obs.complete();
          });
      });
    })
  );
