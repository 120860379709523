import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { GetRevenueInformationUseCase } from "../../../../domain/usecases/order";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, getRevenueInformationSuccess, getRevenueInformationFail } from "../../actions/orders/order.actions";
import { notifySuccess } from "../../../../utils/toast";
export const getRevenueInformationEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.GET_REVENUEINFORMATION),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetRevenueInformationUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getRevenueInformationSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getRevenueInformationFail(error.message));
                    obs.complete();
                });
            });
        })
    );


