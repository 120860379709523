import { combineEpics } from "redux-observable";
import { getSalesRepresentativesEpic } from "./getSalesRepresentativesEpic";
import { addSaleRepresentativeEpic } from "./addSaleRepresentativeEpic";
import { importSaleEpic } from "./importSaleEpic";
import { deleteSalesEpic } from "./deleteSalesEpic";
import { updateSaleEpic } from "./updateSaleEpic";

export const salesEpic = combineEpics(
  getSalesRepresentativesEpic,
  addSaleRepresentativeEpic,
  importSaleEpic,
  deleteSalesEpic,
  updateSaleEpic
);
