import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { ExportPurchaseOrdersUseCase, ResendOrderConfirmationUseCase } from "../../../../domain/usecases/order";
import ExportOrdersUseCase from "../../../../domain/usecases/order/ExportOrdersUseCase";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { OrderActionTypes, exportOrdersSuccess, exportOrdersFail, exportPurchaseOrdersSuccess, exportPurchaseOrdersFail, resendOrderConfirmationSuccess, resendOrderConfirmationFail } from "../../actions/orders/order.actions";


export const resendOrderConfirmationEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.RESEND_ORDER_CONFIRMATION),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ResendOrderConfirmationUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('注文情報の再送に成功しました!');
                    obs.next(resendOrderConfirmationSuccess(data));
                    obs.complete();
                }).catch(error => {
                    obs.next(resendOrderConfirmationFail(error.message));
                    obs.complete();
                });
            });
        })
    );


