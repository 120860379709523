import BackendResource from '../../../gateway/api/resource/order';
import ResponseModel from '../../../../models/response';
import { getConfig } from '../../../setting/graphConfig';
import ApiGateway from '../../../gateway/api';
import { IGetRevenueInformationPayload, IChangeOrderStatusPayload, IGetOrderPayload, IGetOrdersPayload, IExportOrdersPayload, IUpdateOrderPayload, IUpdateOrderProuctsPayload, IExportPurchaseOrdersPayload, IGenerateProductsPdf, IExportPosOrdersPayload, GetReturnRequestsPayload, GetReturnRequestsData, ReturnRequest, UpdateReturnRequestStatusPayload, UpdateReturnRequestStaffNotesPayload, ExportReturnRequestByIdsPayload, ExportReturnRequestByScopePayload, ExportReturnRequestData, UpdateReturnRequestProductStatusPayload, ReturnRequestProduct } from '../../../../presentation/redux/actions/interfaces/order.types';
import { APIResponse } from '../../../../models/response/types';

const getOrder = (payload: IGetOrderPayload): Promise<ResponseModel<string>> => {
    const orderId = payload.id;
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetOrder(orderId);
   
    return apiGateway.doGetRequest(ordersResource);
};

const getOrders = (payload: IGetOrdersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetOrders(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const getRevenueInformation = (payload: IGetRevenueInformationPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetRevenueInformation(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const changeOrderStatus = (payload: IChangeOrderStatusPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ChangeOrderStatus();
   
    return apiGateway.doPutRequest(ordersResource, payload);
};

const exportOrdersHistories = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportOrdersHistories(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const changeOrderProductStatus = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ChangeOrderProductsStatus();
   
    return apiGateway.doPutRequest(ordersResource, payload);
};

const exportOrders = (payload: IExportOrdersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportOrders(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const exportOrdersV2 = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportOrdersV2(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const exportSales = (payload: IExportOrdersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportSales();
   
    return apiGateway.doPostRequest(ordersResource, payload);
};

const updateOrder = (payload: IUpdateOrderPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.UpdateOrder();
   
    return apiGateway.doPutRequest(ordersResource, payload);
};

const updateOrderProducts = (payload: IUpdateOrderProuctsPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.UpdateOrderProducts();
   
    return apiGateway.doPutRequest(ordersResource, payload);
};

const exportPurchaseOrders = (payload: IExportPurchaseOrdersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportPurchaseOrders(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const exportSakayasInfoSummary = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportSakayasInfoSummary();
   
    return apiGateway.doGetRequest(ordersResource);
};
const resendOrderConfirmation = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ResendOrderConfirmation(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

const getPurchasedProducts = (customerId: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetPurchasedProducts(customerId);
   
    return apiGateway.doGetRequest(ordersResource);
};

const sendProductRejectedNotification = (orderId: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.SendProductRejectedNotification(orderId);
   
    return apiGateway.doGetRequest(ordersResource);
};

const saveFirebaseToken = (token: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.SaveFirebaseToken();
   
    return apiGateway.doPutRequest(ordersResource, {token: token});
};

const getOrderSummary = (payload: IGetOrdersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetOrderSummary(payload);
   
    return apiGateway.doGetRequest(ordersResource);
};

export const getReturnRequests = (payload: GetReturnRequestsPayload): Promise<ResponseModel<APIResponse<GetReturnRequestsData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetReturnRequests(payload);
    return apiGateway.doGetRequest(ordersResource);
};

export const getReturnRequestById = (id: string): Promise<ResponseModel<APIResponse<ReturnRequest>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetReturnRequestById(id);
    return apiGateway.doGetRequest(ordersResource);
};

export const exportReturnRequestByIds = (payload: ExportReturnRequestByIdsPayload): Promise<ResponseModel<APIResponse<ExportReturnRequestData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportReturnRequestByIds(payload);
    return apiGateway.doGetRequest(ordersResource);
};

export const exportReturnRequestsByScope = (payload: ExportReturnRequestByScopePayload): Promise<ResponseModel<APIResponse<ExportReturnRequestData>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportReturnRequestByScope(payload);
    return apiGateway.doGetRequest(ordersResource);
}

export const sendEmailReturnRequest = (id: string): Promise<ResponseModel<APIResponse<APIResponse<null>>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.SendEmailReturnRequest(id);
    return apiGateway.doPostRequest(ordersResource);
}

export const updateReturnRequestStaffNotes = (payload: UpdateReturnRequestStaffNotesPayload): Promise<ResponseModel<APIResponse<ReturnRequest>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.UpdateReturnRequestStaffNotes();
    return apiGateway.doPutRequest(ordersResource, payload);
};

export const updateReturnRequestStatus = (payload: UpdateReturnRequestStatusPayload): Promise<ResponseModel<APIResponse<ReturnRequest>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.UpdateReturnRequestStatus();
    return apiGateway.doPutRequest(ordersResource, payload);
};

export const updateReturnRequestProductsStatus = (payload: UpdateReturnRequestProductStatusPayload): Promise<ResponseModel<APIResponse<ReturnRequestProduct[]>>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.UpdateReturnRequestProductsStatus();
    return apiGateway.doPutRequest(ordersResource, payload);
}

const getSakayasInfoSummary = (): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.getSakayasInfoSummary();
    return apiGateway.doGetRequest(ordersResource);
};

const generateProductsPdf = (payload: IGenerateProductsPdf): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GenerateProductsPdf();
   
    return apiGateway.doPostRequest(ordersResource, payload);
};

const createSelectedCollection = (payload: IGenerateProductsPdf): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.CreateSelectedCollection();
   
    return apiGateway.doPostRequest(ordersResource, payload);
};

const deleteCollectedOption = (id: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.DeleteCollectedOption(id);
   
    return apiGateway.doDeleteRequest(ordersResource);
};

const getProductsSelected = (customerId: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetProductsSelected(customerId);
   
    return apiGateway.doGetRequest(ordersResource);
};

const getFaxFiles = (includeCompleted: boolean): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.GetFaxFiles(includeCompleted);
   
    return apiGateway.doGetRequest(ordersResource);
};

const faxContent = (faxId: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.FaxContent(faxId);
   
    return apiGateway.doGetRequest(ordersResource);
};

const cancelFax = (faxId: string): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.CancelFax(faxId);
   
    return apiGateway.doPutRequest(ordersResource);
};

const scanFaxImages = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ScanFaxImages();
   
    return apiGateway.doPostRequest(ordersResource,payload);
};

const createOrder = (payload: any): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.CreateOrder();
   
    return apiGateway.doPostRequest(ordersResource,payload);
};

const exportOrdersPos = (payload: IExportPosOrdersPayload): Promise<ResponseModel<string>> => {
    const apiGateway = ApiGateway.createAPIConnection(getConfig());
    const ordersResource = BackendResource.ExportOrdersPos();
   
    return apiGateway.doPostRequest(ordersResource, payload);
};

export default {
    getOrder,
    getOrders,
    changeOrderStatus,
    exportOrdersHistories,
    changeOrderProductStatus,
    exportOrders,
    exportOrdersV2,
    exportSales,
    updateOrder,
    updateOrderProducts,
    getRevenueInformation,
    exportPurchaseOrders,
    exportSakayasInfoSummary,
    resendOrderConfirmation,
    getPurchasedProducts,
    sendProductRejectedNotification,
    saveFirebaseToken,
    getOrderSummary,
    getSakayasInfoSummary,
    generateProductsPdf,
    createSelectedCollection,
    deleteCollectedOption,
    getProductsSelected,
    scanFaxImages,
    createOrder,
    getFaxFiles,
    faxContent,
    cancelFax,
    exportOrdersPos
};
