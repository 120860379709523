import { IAction, IState } from "../../../../shared/interfaces/common";
import { ProductActionTypes } from "../../actions/products/product.actions";
import ReducerHelper from "../../rootReducers/helper";

const initialState: IState<string> = ReducerHelper.baseReducer();

export const getAlarmProductsFileReducer = (
  state = initialState,
  action: IAction<any>
) => {
  switch (action.type) {
    case ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE: {
      return ReducerHelper.baseProcessRequest(state, action);
    }

    case ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE_SUCCESS: {
      return ReducerHelper.baseProcessSuccess(state, action);
    }

    case ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE_FAIL: {
      return ReducerHelper.baseProcessFailed(state, action);
    }

    default: {
      return state;
    }
  }
};
