import { IAction, IState } from "../../../../shared/interfaces/common";
import { OrderActionTypes } from "../../actions/orders/order.actions";
import ReducerHelper from "../../rootReducers/helper";
const initialHidePriceTotal: IState<string> = ReducerHelper.baseReducer();

export const setHidePriceTotal = (
  state = initialHidePriceTotal,
  action: IAction<any>,
) => {
  switch (action.type) {
    case OrderActionTypes.IS_HIDE_PRICE_TOTAL:
      return {
        isHidePriceTotal: action.payload,
      };
  }
  return state;
};
