import InvoiceCloud from "./cloud";
import InvoiceStorage from "./storage";
import CmsCloud from "./cms/index";
import { ICompleteUploadPayload, ICreateBulkInvoiceJobPayload, IDownloadInvoiceBillPayload, IGetBulkUploadHistoriesPayload, IGetInvoiceListPayload, IGetInvoicePayload, IGetSignedUrlPayload, IPublishInvoicesPayload, ISearchInvoicesPayload, ISingleInvoicePayload, ISwitchInvoiceStatusPayload } from "../../../presentation/redux/actions/interfaces/invoices.types";

export default class InvoiceRepository {
  public searchInvoices = (payload: ISearchInvoicesPayload) => {
    return InvoiceCloud.searchInvoices(payload);
  };

  public hasPendingJob = () => {
    return InvoiceCloud.hasPendingJob();
  };

  public createInvoice = (payload: any) => {
    return InvoiceCloud.createInvoice(payload);
  };

  public createInvoiceVersion = (payload: any) => {
    return InvoiceCloud.createInvoiceVersion(payload);
  };

  public deleteInvoice = (payload: ISingleInvoicePayload) => {
    return InvoiceCloud.deleteInvoice(payload);
  };

  public restoreInvoice = (payload: ISingleInvoicePayload) => {
    return InvoiceCloud.restoreInvoice(payload);
  };

  public downloadInvoice = (payload: ISingleInvoicePayload) => {
    return InvoiceCloud.downloadInvoice(payload);
  };

  public sendInvoiceReminder = (payload: ISingleInvoicePayload) => {
    return InvoiceCloud.sendInvoiceReminder(payload);
  };

  public createBulkUploadJob = (payload: ICreateBulkInvoiceJobPayload) => {
    return InvoiceCloud.createBulkUploadJob(payload);
  };

  public searchDeletedInvoices = (payload: ISearchInvoicesPayload) => {
    payload.isSearchDeleted = true;
    return InvoiceCloud.searchInvoices(payload);
  };

  public searchDraftInvoices = (payload: ISearchInvoicesPayload) => {
    payload.isSearchDraft = true;
    return InvoiceCloud.searchInvoices(payload);
  };

  public publishInvoices = (payload: IPublishInvoicesPayload) => {
    return InvoiceCloud.publishInvoices(payload);
  };

  public getBulkUploadHistories = (payload: IGetBulkUploadHistoriesPayload) => {
    return InvoiceCloud.getBulkUploadHistories(payload);
  };

  public switchInvoiceStatus = (payload: ISwitchInvoiceStatusPayload) => {
    return InvoiceCloud.switchInvoiceStatus(payload);
  };

  public getSignedUrl = (payload: IGetSignedUrlPayload) => {
    return InvoiceCloud.getSignedUrl(payload);
  };

  public completeUpload = (payload: ICompleteUploadPayload) => {
    return InvoiceCloud.completeUpload(payload);
  };
  public getInvoiceDetail = (payload: IGetInvoicePayload) => {
    return InvoiceCloud.getInvoiceDetail(payload);
  };
  public getInvoiceList = (payload: IGetInvoiceListPayload) => {
    return InvoiceCloud.getInvoiceList(payload);
  };

  public downloadInvoiceBill = (payload: IDownloadInvoiceBillPayload) => {
    return InvoiceCloud.downloadInvoiceBill(payload);
  };
}

