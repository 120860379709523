import {gql} from 'graphql-request';

const PROMOTION_BANNER_FIELDS = gql`
  fragment promotionBannerFields on PromotionBanner {
    title
    description
    promoCode
    promoCodeDescription
    image {
      url
    }
    voucher {
      id
      voucherCode
    }
  }
`;

const SUBSCRIPTION_COUPON_FIELDS = gql`
  fragment subscriptionCouponFields on SubscriptionCouponsBanner {
    title
    subscriptionCoupons {
      ...couponFields
    }
  }
`;


const COUPON_FIELDS = gql`
  fragment couponFields on Voucher {
    id
    title
    voucherCode
    description
    enabled
    image {
      url
    }
  }
`;

const UP_SELL_FIELDS = gql`
  fragment upSellFields on Upsell {
    title
    type
    ctaText
    products {
      id
    }
  }
`;




export const getOrdersQuery = (path: string) => {
    return gql`${PROMOTION_BANNER_FIELDS} ${SUBSCRIPTION_COUPON_FIELDS} ${COUPON_FIELDS} ${UP_SELL_FIELDS} query {
      page(locales: [ja], where: {url: "${path}"}) {
        mobileComponents {
          __typename
            ... on OrderInfo {
              myOrdersComponents {
                __typename
                ... on PromotionBanner {
                  ...promotionBannerFields
                }
                ... on SubscriptionCouponsBanner {
                  ...subscriptionCouponFields
                }
                ... on Upsell {
                  ...upSellFields
                }
                ... on OrderFilter {
                  orderTypeTitle
                  orderStatusFilterTags {
                    code
                    title
                  }
                  orderDateTitle
                  orderDateFilterTags {
                    code
                    title
                  }
                }

              }
              subscriptionsComponents {
                __typename
                ... on PromotionBanner {
                  ...promotionBannerFields
                }
                ... on SubscriptionCouponsBanner {
                  ...subscriptionCouponFields
                }
                ... on Upsell {
                  ...upSellFields
                }
              }
              buyAgainComponents {
                __typename
                ... on PromotionBanner {
                  ...promotionBannerFields
                }
                ... on SubscriptionCouponsBanner {
                  ...subscriptionCouponFields
                }
                ... on Upsell {
                  ...upSellFields
                }
              }

            }

        }
      }
    }`;
  }

 

  