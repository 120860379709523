import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { SetOverrideDisplayUseCase } from "../../../../domain/usecases/products";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { ProductActionTypes, setOverrideDisplaySuccess, setOverrideDisplayFail } from "../../actions/products/product.actions";


export const setOverrideDisplayEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.SET_OVERRIDE_DISPLAY_PRODUCT),
        exhaustMap((action: IAction<any>) => {
            return new Observable(obs => {
                const { payload } = action;
                const usecase = new SetOverrideDisplayUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses.data;
                    notifySuccess("Set the product to display mode!");
                    obs.next(setOverrideDisplaySuccess(data));
                    obs.complete();
                }).catch(error => {
                    notifyError(error.message);
                    obs.next(setOverrideDisplayFail(error.message));
                    obs.complete();
                });
            });
        })
    );


