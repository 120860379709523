import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { OrderActionTypes, createSelectedCollectionSuccess, createSelectedCollectionFail } from "../../actions/orders/order.actions";
import { CreateSelectedCollectionUseCase } from "../../../../domain/usecases/order";
import { notifySuccess } from "../../../../utils/toast";

export const createSelectedCollectionEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(OrderActionTypes.CREATE_SELECTED_OPTIONS),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new CreateSelectedCollectionUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    notifySuccess('商品コレクションの更新完了!');
                    obs.next(createSelectedCollectionSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(createSelectedCollectionFail(error.message));
                    obs.complete();
                });
            });
        })
    );


