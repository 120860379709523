import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifyError, notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, restoreInvoiceFail, restoreInvoiceSuccess, searchInvoicesFail, searchInvoicesSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import { RestoreInvoiceUseCase } from "../../../../domain/usecases/invoices";


export const restoreInvoiceEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.RESTORE_INVOICE),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new RestoreInvoiceUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(restoreInvoiceSuccess(data.data));
                    notifySuccess('請求書の復元が完了しました');
                    obs.complete();
                }).catch(error => {
                    obs.next(restoreInvoiceFail(error.message));
                    notifyError('請求書の復元が完了できませんでした。再度お試しください');
                    obs.complete();
                });
            });
        })
    );


