import ProductRepository from "../../../data/repository/product";
import ResponseModel from "../../../models/response";
import { IUseCase } from "../../../shared/interfaces/common/usecase";

export default class GetAlarmProductsFileUseCase implements IUseCase {
    private productRepository: ProductRepository;


  constructor(public payload: string) {
    this.productRepository = new ProductRepository();
  }

  execute = (): Promise<ResponseModel<string>> => {
    return new Promise((resolve, reject) => {
      this.productRepository.getAlarmProductsFile(this.payload)
        .then((response: any) => {
        // console.log('PAYLOAS SUCCESS', response)

          if (response.data.data) {
            resolve(response.data);
          } else {
            reject(ResponseModel.createError(500, response.data.errorMessage));
          }
        })
        .catch(reject);
    });
  };
}
