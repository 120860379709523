import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { notifySuccess } from "../../../../utils/toast";
import { InvoiceActionTypes, getSignedUrlFail, getSignedUrlSuccess, hasPendingJobFail, hasPendingJobSuccess, searchInvoicesFail, searchInvoicesSuccess } from "../../actions/invoices/invoices.actions";
import SearchInvoicesUseCase from "../../../../domain/usecases/invoices/SearchInvoicesUseCase";
import HasPendingJobUseCase from "../../../../domain/usecases/invoices/HasPendingJobUseCase";
import { GetSignedUrlUseCase } from "../../../../domain/usecases/invoices";


export const getSignedUrlEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(InvoiceActionTypes.GET_SIGNED_URL),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new GetSignedUrlUseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(getSignedUrlSuccess(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(getSignedUrlFail(error.message));
                    obs.complete();
                });
            });
        })
    );


