import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { TokenType } from '../../../data/constants';

interface IProps {
  children: any;
}

const PrivateRoute = ({ children, ...rest }: IProps) => {
  const [isLogin, setIsLogin] = React.useState(() => !!localStorage.getItem(TokenType.AccessToken));

  return (
    <Route
      {...rest}
      render={({ location }) =>
      isLogin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
