import { ofType } from "redux-observable";
import { Observable } from "rxjs";
import { exhaustMap, switchMap } from "rxjs/operators";
import { IAction } from "../../../../shared/interfaces/common";
import { exportPricesV2Fail, exportPricesV2Success, ProductActionTypes } from "../../actions/products/product.actions";
import { ExportPricesV2UseCase } from "../../../../domain/usecases/products";

export const exportPricesV2Epic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(ProductActionTypes.EXPORT_PRICES_V2),
        exhaustMap((action: IAction<any>) => {

            return new Observable(obs => {
                const { payload } = action;
                const usecase = new ExportPricesV2UseCase(payload);
                usecase.execute().then((responses: any) => {
                    let data = responses;
                    obs.next(exportPricesV2Success(data.data));
                    obs.complete();
                }).catch(error => {
                    obs.next(exportPricesV2Fail(error.message));
                    obs.complete();
                });
            });
        })
    );


